import { useEffect, useCallback, useRef } from "react";
import { fetchData } from "../utils/fetchData";

const INACTIVITY_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds
const CHECK_INTERVAL = 10 * 1000; // Check every 10 seconds
const ACTIVITY_UPDATE_INTERVAL = 15 * 60 * 1000; // 15 minutes in milliseconds

export const useInactivityCheck = () => {
  const lastActivity = useRef(Date.now()); // Tracks the last user activity time
  const lastUpdate = useRef(null); // Tracks the last time activity was updated to the server
  const isActive = useRef(true); // Tracks if the user is active
  const checkIntervalRef = useRef(null); // Tracks the interval ID for clearing later

  const handleUserActivity = useCallback(() => {
    lastActivity.current = Date.now(); // Update the last activity timestamp
    if (!isActive.current) {
      console.log("User activity detected. Resuming updates.");
      isActive.current = true; // Mark user as active
    }
  }, []);

  const updateAdminActivity = useCallback(async () => {
    const token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : null;

    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const lastActivityTime = new Date(lastActivity.current).toISOString(); // Format last activity time
      await fetchData(
        {
          request: "admins/update-activity",
          data: { lastActive: lastActivityTime }, // Send the last activity time
        },
        "POST",
        token
      );
      console.log(`Admin activity updated to: ${lastActivityTime}`);
      lastUpdate.current = Date.now(); // Update the last update timestamp
    } catch (error) {
      console.error("Error updating admin activity:", error);
    }
  }, []);

  useEffect(() => {
    const checkInactivity = async () => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivity.current;

      // If no activity for the defined time, mark user as inactive
      if (timeSinceLastActivity >= INACTIVITY_TIME) {
        if (isActive.current) {
          console.log("User is now inactive. Stopping updates.");
          isActive.current = false; // Mark user as inactive
        }
        return; // Stop further updates
      }

      // Update admin activity every ACTIVITY_UPDATE_INTERVAL
      if (
        (!lastUpdate.current ||
          now - lastUpdate.current >= ACTIVITY_UPDATE_INTERVAL) &&
        isActive.current
      ) {
        await updateAdminActivity();
      }
    };

    const events = ["click"];

    events.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    checkIntervalRef.current = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [handleUserActivity, updateAdminActivity]);

  return null; // No UI to render
};
