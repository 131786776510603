import React from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../context/usePermissions";
import { MdSentimentDissatisfied } from "react-icons/md"; // אייקון חלופי

export const withPermissions = (WrappedComponent, pageName) => {
  return function WithPermissionsComponent(props) {
    const { hasPermission, loading } = usePermissions();
    if (loading) {
      return <div>טוען...</div>;
    }

    const hasAccess =
      hasPermission(pageName, "read") &&
      (!pageName.includes(".") ||
        hasPermission(pageName.split(".")[1], "read"));

    if (!hasAccess) {
      return (
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100 h-100"
          style={{ marginTop: "100px" }}
        >
          <MdSentimentDissatisfied
            size={50}
            className="text-secondary mb-3" // צבע ותוספת מרווח
          />
          <h2 className="text-secondary text-center">
            אין לך הרשאות לצפות בדף זה
          </h2>
        </div>
      );
    }

    return <WrappedComponent {...props} hasPermission={hasPermission} />;
  };
};
