import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ClientDetailsInfoTab from "./ClientDetailsInfoTab";
import ClientOrganizersTab from "./ClientOrganizersTab";
import ClientFeeTab from "./ClientFeeTab";
import ClientDocumentsTab from "./ClientDocumentsTab";
import ClientSettingsTab from "./ClientsSettingsTab";
import { CLIENTS_SECTIONS } from "../../../../config/permissions";
import { usePermissions } from "../../../../context/usePermissions";
import ClientCancellationRulesTab from "./ClientCancellationRulesTab";
import { fetchData } from "../../../../utils/fetchData";
import { useToast } from "../../../../context/ToastContext";

export default function ClientDetailsContentSection({
  handleTabSelect,
  client,
  isEditing,
  editedClient,
  handleInputChange,
  handleCancelEdit,
  handleEditToggle,
  handleImageUpload,
  handleSaveChanges,
  fetchClientDetails,
  context,
  canEdit,
}) {
  const { hasPermission } = usePermissions();
  const { showErrorToast, showSuccessToast } = useToast();

  const tabs = [
    {
      eventKey: "details",
      title: "פרטים",
      permission: CLIENTS_SECTIONS.DETAILS,
      component: (
        <ClientDetailsInfoTab
          client={client}
          isEditing={isEditing}
          editedClient={editedClient}
          handleInputChange={handleInputChange}
          handleCancelEdit={handleCancelEdit}
          handleEditToggle={handleEditToggle}
          handleImageUpload={handleImageUpload}
          handleSaveChanges={handleSaveChanges}
          context={context}
          canEdit={canEdit}
        />
      ),
      showCondition: true,
    },
    {
      eventKey: "organizers",
      title: "סדרנים",
      permission: "clients.organizers",
      component: <ClientOrganizersTab client={client} context={context} />,
      showCondition: !client.hasBranches,
    },
    {
      eventKey: "fee",
      title: "עמלות",
      permission: "clients.fees",
      component: <ClientFeeTab client={client} context={context} />,
      showCondition: !client.hasBranches,
    },

    {
      eventKey: "cancellationRules",
      title: "החזרת נסיעה",
      permission: "clients.settings",
      component: (
        <ClientCancellationRulesTab
          client={client}
          context={context}
          fetchClientDetails={fetchClientDetails}
        />
      ),
      showCondition: !client.hasBranches,
    },
    {
      eventKey: "documents",
      title: "מסמכים",
      permission: "clients.documents",
      component: (
        <ClientDocumentsTab
          client={client}
          fetchClientDetails={fetchClientDetails}
        />
      ),
      showCondition: !client.hasBranches,
    },
    {
      eventKey: "settings",
      title: "אחר",
      permission: "clients.settings",
      component: (
        <ClientSettingsTab
          client={client}
          fetchClientDetails={fetchClientDetails}
        />
      ),
      showCondition: true,
    },
  ];

  return (
    <>
      <Card className="border border-1">
        <Card.Body className="p-3">
          <Tabs
            defaultActiveKey="details"
            id="trip-details-tabs"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            {tabs.map(
              (tab) =>
                tab.showCondition &&
                hasPermission(tab.permission, "read") && (
                  <Tab
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={tab.title}
                  >
                    {tab.component}
                  </Tab>
                )
            )}
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
}
