import React, { useCallback, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";

const SearchForm = memo(
  ({
    searchTerm,
    setSearchTerm,
    statusFilter,
    setStatusFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    carTypeFilter,
    setCarTypeFilter,
    selectedTime,
    setSelectedTime,
    selectedCompanies,
    setSelectedCompanies,
    carTypes,
    onSearch,
    updateUrlParams,
    fetchTrips,
    setCurrentPage,
    fetchCompanies,
  }) => {
    const navigate = useNavigate();
    const [isClearingFilters, setIsClearingFilters] = useState(false);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

    useEffect(() => {
      if (isClearingFilters) {
        // Reset the flag
        setIsClearingFilters(false);

        // Update URL params and fetch trips
        updateUrlParams(
          {
            page: 1,
            status: "all",
            search: "",
            carType: "all",
            fromDate: null,
            toDate: null,
            time: "",
            company: "",
          },
          true
        );

        fetchTrips();
        navigate("/dashboard/trips");
      }
    }, [isClearingFilters, updateUrlParams, fetchTrips, navigate]);

    const loadCompanies = useCallback(async () => {
      if (isLoadingCompanies || companiesOptions.length > 0) return;

      setIsLoadingCompanies(true);
      try {
        const response = await fetchCompanies();
        if (response.success) {
          setCompaniesOptions(
            response.companies.map((company) => ({
              value: company,
              label: company,
            }))
          );
        }
      } catch (error) {
        console.error("Error loading companies:", error);
      } finally {
        setIsLoadingCompanies(false);
      }
    }, [fetchCompanies, companiesOptions.length, isLoadingCompanies]);

    useEffect(() => {
      loadCompanies();
    }, [loadCompanies]);

    const handleClearAllFilters = useCallback(() => {
      setSearchTerm("");
      setStatusFilter("all");
      setStartDate(null);
      setEndDate(null);
      setCarTypeFilter("all");
      setSelectedTime("");
      setSelectedCompanies([]);
      setCurrentPage(1);
      setIsClearingFilters(true);
    }, [
      setSearchTerm,
      setStatusFilter,
      setStartDate,
      setEndDate,
      setCarTypeFilter,
      setSelectedTime,
      setSelectedCompanies,
      setCurrentPage,
    ]);

    const fetchAndSetCompanies = async () => {
      const response = await fetchCompanies();
      if (response.success) {
        setCompaniesOptions(
          response.companies.map((company) => ({
            value: company,
            label: company,
          }))
        );
      }
    };

    return (
      <Form>
        <Row className="mb-3 g-3">
          <Col xs={12} md={6}>
            <Form.Control
              type="text"
              placeholder="חפש לפי תיאור הקו, חברה מציעה, חברה מבצעת, הערות, או סוג רכב"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>

          <Col xs={12} md={3}>
            <Form.Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">כל הנסיעות</option>
              <option value="open">נסיעות פתוחות</option>
              <option value="inProgress">נסיעות שנלקחו</option>
              <option value="inProcess">נסיעות בתהליך</option>
              <option value="completed">נסיעות שהסתיימו</option>
              <option value="cancelled">נסיעות שבוטלו</option>
              <option value="missingFields">נסיעות עם חוסרים</option>
            </Form.Select>
          </Col>

          <Col xs={12} md={3}>
            <Form.Select
              value={carTypeFilter}
              onChange={(e) => setCarTypeFilter(e.target.value)}
            >
              {carTypes.map((carType) => (
                <option key={carType.value} value={carType.value}>
                  {carType.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row className="g-3 align-items-center">
          <Col xs={12} md={2}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="תאריך התחלה"
              isClearable
            />
          </Col>

          <Col xs={12} md={2}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="תאריך סיום"
              isClearable
            />
          </Col>

          <Col xs={12} md={3}>
            <Form.Select
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              <option value="">בחר שעה</option>
              {Array.from({ length: 24 }, (_, index) => (
                <option
                  key={index}
                  value={index.toString().padStart(2, "0") + ":00"}
                >
                  {index.toString().padStart(2, "0")}:00
                </option>
              ))}
            </Form.Select>
          </Col>

          <Col xs={12} md={3}>
            <Select
              isMulti
              options={companiesOptions}
              value={selectedCompanies.map((company) => ({
                value: company,
                label: company,
              }))}
              onChange={(selected) => {
                if (companiesOptions.length === 0) {
                  fetchAndSetCompanies();
                }
                setSelectedCompanies(selected.map((item) => item.value));
              }}
              placeholder="בחר חברות"
              classNamePrefix="react-select"
            />
          </Col>

          <Col xs={12} md={1}>
            <Button variant="primary" className="w-100" onClick={onSearch}>
              חיפוש
            </Button>
          </Col>

          <Col xs={12} md={1} className="d-flex justify-content-center">
            <Button
              variant="link"
              className="p-0 text-decoration-underline border-0 shadow-none "
              onClick={handleClearAllFilters}
            >
              נקה הכל
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
);

SearchForm.displayName = "SearchForm";

export default SearchForm;
