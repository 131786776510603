import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import "./toastStyles.css";

const ToastComponent = ({ showToast, setShowToast, type, message }) => {
  return (
    <ToastContainer className="fixed-toast-container">
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={4500}
        bg={type}
        autohide
        className={`custom-toast ${showToast ? "toast-enter" : "toast-exit"}`}
      >
        <Toast.Body className="text-white text-center">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastComponent;
