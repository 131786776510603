import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  existingOrganizers,
  selectedOption,
  setSelectedOption,
  loading,
}) => {
  const onConfirm = () => {
    if (selectedOption) {
      handleConfirm(selectedOption);
    } else {
      alert("נא לבחור פעולה");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="bg-danger text-light" closeButton>
        <Modal.Title className="fw-bold">אישור פעולה</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">
          <strong>שים לב:</strong> זה הסניף הראשון ללקוח. <br />
          לאחר יצירת הסניף, כל הנתונים כגון:
        </p>
        <p className="mb-3">
          סדרנים, עמלות ומסמכים יעברו להיות מנוהלים תחת הסניפים ולא תחת הלקוח.
        </p>
        <p className="mb-4">
          ניתן לחסוך זמן על ידי העתקת הנתונים הקיימים אל הסניף החדש, או לבחור
          למחוק את כל הנתונים הנוכחיים.
        </p>
        <Form>
          <Form.Check
            type="radio"
            label={`העברת ${existingOrganizers} סדרנים לסניף החדש`}
            name="confirmationOptions"
            id="transferOption"
            value="transfer"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
          <Form.Check
            type="radio"
            label="מחיקת כל הנתונים הקיימים"
            name="confirmationOptions"
            id="deleteOption"
            value="delete"
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={loading}>
          ביטול
        </Button>
        <Button
          variant="danger"
          onClick={onConfirm}
          disabled={!selectedOption || loading}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
            </>
          ) : (
            "אישור"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
