import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLoading } from "../../../context/LoadingContext";
import { useToast } from "../../../context/ToastContext";

const OrderDetailsHistoryFetchData = ({ lineCode }) => {
  // Hooks
  const { showLoading, hideLoading } = useLoading();
  const { showErrorToast } = useToast();

  // Component state
  const [historyData, setHistoryData] = useState(null);

  useEffect(() => {
    // Fetch change history data from the API
    const fetchHistoryData = async () => {
      if (!lineCode) return;

      showLoading();
      try {
        const url = `https://interfaceserviceapi.y-it.co.il/FcApiService/FcApiService.svc/InvokeFcApiService?authenticationToken=d8707759-45d2-492a-01a2-c430d1f6c82c&$action=get&$objects=DIARYCHANGELOG&$lineCode=${lineCode}`;
        const response = await axios.get(url);

        if (response?.data?.data?.diaryChangeLogObject) {
          setHistoryData(response.data.data.diaryChangeLogObject[0]);
        } else {
          showErrorToast("שגיאה: לא נמצאו היסטוריית שינויים");
        }
      } catch (err) {
        console.error("History fetch error:", err);
        showErrorToast("שגיאה: לא נמצאו היסטוריית שינויים");
      } finally {
        hideLoading();
      }
    };

    fetchHistoryData();
  }, [lineCode]);

  // Format date from YYYY-MM-DD to DD.MM.YYYY
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  if (!historyData) return null;

  return (
    <div
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      {historyData?.changes?.map((change, index) => (
        <div key={index}>
          <div key={index} className="mb-3 d-flex flex-column">
            <div>
              <strong className="font-weight-bold">שינוי על ידי:</strong>{" "}
              {change.changedByName}
            </div>
            <div>
              <strong className="font-weight-bold">זמן שינוי:</strong>{" "}
              {formatDate(change.changeTime.split(" ")[0])}
            </div>
            <div>
              <strong className="font-weight-bold">אובייקט שינוי:</strong>{" "}
              {change.changeObject}
            </div>
            <div>
              <strong className="font-weight-bold">ערך ישן:</strong>{" "}
              {change.oldValue}
            </div>
            <div>
              <strong className="font-weight-bold">ערך חדש:</strong>{" "}
              {change.newValue}
            </div>
          </div>
          {index !== historyData?.changes?.length - 1 && <hr />}
        </div>
      ))}
    </div>
  );
};

export default OrderDetailsHistoryFetchData;
