import React, { useCallback, useEffect, useRef, useState } from "react";
import { Table, OverlayTrigger, Tooltip, Pagination } from "react-bootstrap";
import "../trips.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import {
  carTypes,
  checkClientExists,
  formatDate,
  formatNumberWithCommas,
} from "../../../utils/supportFunctions";
import "react-datepicker/dist/react-datepicker.css";
import { useLoading } from "../../../context/LoadingContext";
import { useToast } from "../../../context/ToastContext";
import SearchForm from "./SearchForm";
import { useInactivityCheck } from "../../../context/useInactivityCheck";
import MiniDashboard from "../MiniDashboard";
import _ from "lodash";
import BundleTag from "../../../components/BundleTag/BundleTag";
import { IoChevronBack, IoChevronDown } from "react-icons/io5";

const TripsTable = ({ initialState, updateUrlParams }) => {
  const { showLoading, hideLoading } = useLoading();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());

  const isMounted = useRef(true);
  const currentFetchController = useRef(null);
  const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(() => {
    const saved = localStorage.getItem("autoRefreshEnabled");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const lastFetchTime = useRef(Date.now());
  const REFRESH_INTERVAL = 180000; // 3 minutes in milliseconds

  // Pagination states
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [trips, setTrips] = useState([]);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm);
  const [statusFilter, setStatusFilter] = useState(initialState.statusFilter);
  const [carTypeFilter, setCarTypeFilter] = useState(
    initialState.carTypeFilter
  );

  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);
  const [selectedTime, setSelectedTime] = useState(initialState.selectedTime);

  const [selectedCompany, setSelectedCompany] = useState(
    initialState.selectedCompany
  );

  const [userData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );

  useEffect(() => {
    localStorage.setItem(
      "autoRefreshEnabled",
      JSON.stringify(isAutoRefreshEnabled)
    );
  }, [isAutoRefreshEnabled]);

  const { InactivityModal, isInactive } = useInactivityCheck((inactive) => {
    setIsAutoRefreshEnabled(!inactive);
  });

  const fetchTrips = useCallback(
    async (showLoadingIndicator = true) => {
      if (!userData?.token || !isMounted.current || isInactive) return;

      if (currentFetchController.current) {
        currentFetchController.current.abort();
      }

      currentFetchController.current = new AbortController();

      if (showLoadingIndicator) {
        showLoading();
      }

      try {
        const getFormattedDate = (date) => {
          if (!date) return "";
          const localDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
          );
          return localDate.toISOString().split("T")[0];
        };

        const fromDate = getFormattedDate(startDate);
        const toDate = getFormattedDate(endDate);

        const params = new URLSearchParams();
        if (fromDate) params.append("fromDateTime", fromDate);
        if (toDate) params.append("toDateTime", toDate);
        params.append("page", currentPage.toString());
        params.append("limit", limit.toString());
        params.append("status", statusFilter || "all");
        if (searchTerm) params.append("search", searchTerm);
        if (carTypeFilter !== "all") params.append("carType", carTypeFilter);
        if (selectedTime) params.append("time", selectedTime);
        if (selectedCompanies.length > 0) {
          params.append("companies", selectedCompanies.join(","));
        }

        const dataObj = {
          request: `trip?${params.toString()}`,
        };

        const response = await fetchData(
          dataObj,
          "GET",
          userData.token,
          {},
          navigate,
          currentFetchController.current?.signal
        );

        if (response.success) {
          setTrips(response.trips);
          setFilteredTrips(response.trips);
          setTotalPages(response.pagination.totalPages);
          setTotal(response.pagination.total);
          lastFetchTime.current = Date.now();
        } else {
          throw new Error(response.message || "Failed to fetch trips");
        }
      } catch (err) {
        console.error("Error fetching trips:", err);
      } finally {
        if (showLoadingIndicator) {
          hideLoading();
        }
      }
    },
    [
      currentPage,
      limit,
      searchTerm,
      statusFilter,
      carTypeFilter,
      startDate,
      endDate,
      selectedTime,
      selectedCompanies,
      userData?.token,
      showLoading,
      hideLoading,
      showErrorToast,
      isInactive,
    ]
  );

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      if (currentFetchController.current) {
        currentFetchController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) return;

    let intervalId;

    if (isAutoRefreshEnabled) {
      intervalId = setInterval(() => {
        const timeSinceLastFetch = Date.now() - lastFetchTime.current;
        if (timeSinceLastFetch >= REFRESH_INTERVAL) {
          fetchTrips(false);
        }
      }, REFRESH_INTERVAL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchTrips, isAutoRefreshEnabled]);

  useEffect(() => {
    fetchTrips();
  }, [currentPage]);

  const fetchCompanies = async () => {
    try {
      const response = await fetchData(
        { request: "trip/companies" },
        "GET",
        userData?.token,
        {},
        navigate
      );
      return response;
    } catch (error) {
      console.error("Error fetching companies:", error);
      return { success: false };
    }
  };

  const AutoRefreshToggle = () => (
    <div className="d-flex align-items-center mb-3">
      <div className="custom-control custom-switch">
        <label className="custom-control-label" htmlFor="autoRefreshToggle">
          רענון אוטומטי ({REFRESH_INTERVAL / 60000} דקות)
        </label>
      </div>
    </div>
  );

  const handleSearch = useCallback(() => {
    updateUrlParams(
      {
        page: 1,
        status: statusFilter,
        search: searchTerm,
        carType: carTypeFilter,
        fromDate: startDate,
        toDate: endDate,
        time: selectedTime,
        companies: selectedCompanies.join(","),
      },
      true
    );

    setCurrentPage(1);
    fetchTrips();
  }, [
    statusFilter,
    searchTerm,
    carTypeFilter,
    startDate,
    endDate,
    selectedTime,
    selectedCompanies,
    fetchTrips,
    updateUrlParams,
  ]);

  useEffect(() => {
    const saveState = () => {
      try {
        sessionStorage.setItem("currentPage", JSON.stringify(currentPage));
        sessionStorage.setItem("searchTerm", JSON.stringify(searchTerm));
        sessionStorage.setItem("statusFilter", JSON.stringify(statusFilter));
        sessionStorage.setItem("carTypeFilter", JSON.stringify(carTypeFilter));
        sessionStorage.setItem("startDate", JSON.stringify(startDate));
        sessionStorage.setItem("endDate", JSON.stringify(endDate));
        sessionStorage.setItem("selectedTime", JSON.stringify(selectedTime));
        sessionStorage.setItem(
          "selectedCompany",
          JSON.stringify(selectedCompany)
        );
      } catch (error) {
        console.error("Error saving state:", error);
      }
    };

    saveState();
  }, [
    currentPage,
    searchTerm,
    statusFilter,
    carTypeFilter,
    startDate,
    endDate,
    selectedTime,
    selectedCompany,
  ]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("currentPage");
      sessionStorage.removeItem("searchTerm");
      sessionStorage.removeItem("statusFilter");
      sessionStorage.removeItem("carTypeFilter");
      sessionStorage.removeItem("startDate");
      sessionStorage.removeItem("endDate");
      sessionStorage.removeItem("selectedTime");
      sessionStorage.removeItem("selectedCompany");
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const displayTableState = () => {
    return (
      <div className="custom-table-container">
        <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
          <div>
            <p className="lead text-darker font-weight-black text-center mb-0">
              לא נמצאו תוצאות
            </p>
            <p className="text-muted text-center mb-4">
              נסה להשתמשים בערכים אחרים בחיפוש
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleRowClick = (id) => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    navigate(`/dashboard/trip-details/${id}`);
  };

  const getMissingFields = (trip) => {
    const missingFields = [];
    if (!trip.clientPrice || trip.clientPrice <= 0) {
      missingFields.push("מחיר נהג");
    }
    if (!trip.orderCarType) {
      missingFields.push("סוג רכב");
    }
    return missingFields;
  };

  const checkBranchExists = async (branchName) => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) return null;

      const token = JSON.parse(userData).token;
      if (!token) return null;

      const response = await fetchData(
        {
          request: `trip/check-branch/${encodeURIComponent(branchName)}`,
        },
        "GET",
        token,
        {},
        navigate
      );

      if (response.success && response.branch) {
        return response.branch;
      }
      return null;
    } catch (error) {
      console.error("Error checking branch:", error);
      return null;
    }
  };

  const handleNavigateToPerformingCompany = async (companyName) => {
    try {
      if (!companyName) {
        showErrorToast("שם חברה חסר");
        return;
      }
      const branchResult = await checkBranchExists(companyName);
      if (branchResult) {
        navigate(`/dashboard/branches/${branchResult.id}`);
        return;
      }
      const clientResult = await checkClientExists(companyName);
      if (clientResult) {
        navigate(`/dashboard/clients/${clientResult.id}`);
        return;
      }

      showErrorToast("לא נמצא סניף או לקוח מתאים");
    } catch (error) {
      console.error("Error navigating to company page:", error);
      showErrorToast("שגיאה בניווט לדף החברה");
    }
  };

  const PaginationComponent = () => {
    let items = [];
    const maxPages = 5; // Maximum number of page numbers to show

    // Calculate range of pages to show
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    let endPage = Math.min(totalPages, startPage + maxPages - 1);

    // Adjust start if we're near the end
    if (endPage - startPage + 1 < maxPages) {
      startPage = Math.max(1, endPage - maxPages + 1);
    }

    // First page
    items.push(
      <Pagination.First
        key="first"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />
    );

    // Previous page
    items.push(
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />
    );

    // Page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Next page
    items.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />
    );

    // Last page
    items.push(
      <Pagination.Last
        key="last"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    );

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Pagination>{items}</Pagination>
          <div className="mx-3 d-flex justify-content-center align-items-center mt-2">
            <span className="text-muted">
              מציג {(currentPage - 1) * limit + 1} -{" "}
              {Math.min(currentPage * limit, total)} מתוך {total} תוצאות
            </span>
          </div>
        </div>
      </div>
    );
  };

  const groupAndProcessBundles = (trips) => {
    const bundleGroups = _.groupBy(
      trips.filter((t) => t.bundleData?.isBundle),
      (trip) =>
        `${trip.orderStartTime.split(" ")[0]}_${trip.clientName}_${
          trip.setCode
        }`
    );

    const bundleSummaries = Object.entries(bundleGroups).map(
      ([key, bundleTrips]) => {
        const firstTrip = bundleTrips[0];
        return {
          ...firstTrip,
          isBundleSummary: true,
          orderStartTime: firstTrip.bundleData.bundleStartTime,
          orderEndTime: firstTrip.bundleData.bundleEndTime,
          bundleSummary: {
            setCode: firstTrip.setCode,
            totalPrice: firstTrip.bundleData.bundleTotalPrice,
            uniqueCarTypes: _.uniq(
              firstTrip.bundleData.trips.map((t) => t.orderCarType)
            )
              .filter(Boolean)
              .join(" / "),
            totalPassengers: firstTrip.bundleData.bundleTotalPassengers,
            totalStations: firstTrip.bundleData.bundleTotalStations,
            tripsCount: firstTrip.bundleData.bundleSize,
            subTrips: firstTrip.bundleData.trips.filter(
              (t) =>
                t.orderStartTime.split(" ")[0] ===
                  firstTrip.orderStartTime.split(" ")[0] &&
                t.clientName === firstTrip.clientName &&
                t.setCode === firstTrip.setCode
            ),
          },
        };
      }
    );

    return [
      ...trips.filter((t) => !t.bundleData?.isBundle),
      ...bundleSummaries,
    ];
  };
  const toggleBundle = useCallback((bundleId, lineCode, e) => {
    e.stopPropagation();
    const rowKey = `${bundleId}_${lineCode}`;
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(rowKey)) {
        newSet.delete(rowKey);
      } else {
        newSet.add(rowKey);
      }
      return newSet;
    });
  }, []);

  const renderTripRow = (item) => {
    if (item.isBundleSummary) {
      return (
        <React.Fragment key={`bundle_${item.bundleId}_${item.lineCode}`}>
          {/* שורת סיכום בנדל */}
          <tr
            className={classNames(
              "bundle-summary-row",
              "table-row",
              "custom-regular-background", // הוספנו את זה
              {
                "table-info-static":
                  item.performingCompanyName && item.isActive === "1",
                "custom-danger-background line-through":
                  item.isActive === "0" ||
                  item.lineDescription.includes("נסיעה בוטלה"),
                "error-color-class": getMissingFields(item).length > 0,
              }
            )}
            onClick={(e) => toggleBundle(item.bundleId, item.lineCode, e)}
            style={{ cursor: "pointer" }}
          >
            <td>{formatDate(item.orderStartTime.split(" ")[0])}</td>
            <td>{item.orderEndTime.split(" ")[1]}</td>
            <td>{item.orderStartTime.split(" ")[1]}</td>
            <td>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <BundleTag
                    color={item.bundleData.bundleColor}
                    count={item.bundleSummary.tripsCount}
                    setCode={item.bundleSummary.setCode}
                  />
                  <button
                    onClick={(e) =>
                      toggleBundle(item.bundleId, item.lineCode, e)
                    }
                    className="d-flex align-items-center gap-1 btn btn-sm btn-light"
                  >
                    <span>הצג נסיעות</span>
                    {expandedRows.has(`${item.bundleId}_${item.lineCode}`) ? (
                      <IoChevronDown size={16} />
                    ) : (
                      <IoChevronBack size={16} />
                    )}
                  </button>
                </div>
                <div>
                  <strong>קוד נסיעה: {item.bundleSummary.setCode}</strong>
                  <div className="text-muted">
                    סה"כ נסיעות: {item.bundleSummary.tripsCount} | נוסעים:{" "}
                    {item.bundleSummary.totalPassengers} | תחנות:{" "}
                    {item.bundleSummary.totalStations} | סוג רכב:{" "}
                    {item.bundleSummary.uniqueCarTypes}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span
                className="hover-link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigateToPerformingCompany(item.clientName);
                }}
              >
                {item.clientName}
              </span>
            </td>
            <td>
              <span
                className="hover-link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigateToPerformingCompany(item.performingCompanyName);
                }}
              >
                {item.performingCompanyName || "-"}
              </span>
            </td>
            <td>-</td>
            <td>
              <span className="font-weight-bold">
                {formatNumberWithCommas(item.bundleSummary.totalPrice)} ₪
              </span>
            </td>
          </tr>

          {/* תת-שורות בנדל */}
          {expandedRows.has(`${item.bundleId}_${item.lineCode}`) &&
            item.bundleSummary.subTrips.map((subTrip) => (
              <tr
                key={subTrip.lineCode}
                className={classNames(
                  "bundle-sub-row",
                  "table-row",
                  "custom-regular-background", // הוספנו את זה
                  {
                    "table-info-static":
                      subTrip.performingCompanyName && subTrip.isActive === "1",
                    "custom-danger-background line-through":
                      subTrip.isActive === "0" ||
                      subTrip.lineDescription.includes("נסיעה בוטלה"),
                    "error-color-class": getMissingFields(subTrip).length > 0,
                  }
                )}
                onClick={() => handleRowClick(subTrip.id)}
                style={{
                  cursor: "pointer",
                  backgroundColor: `${item.bundleData.bundleColor}10`,
                }}
              >
                <td>{formatDate(subTrip.orderStartTime.split(" ")[0])}</td>
                <td>{subTrip.orderEndTime.split(" ")[1]}</td>
                <td>{subTrip.orderStartTime.split(" ")[1]}</td>
                <td>
                  <div>
                    <span
                      style={{ color: "#666", marginRight: "8px" }}
                      className="px-2"
                    >
                      ↲
                    </span>
                    {subTrip.lineDescription}
                  </div>
                </td>
                <td>
                  <span
                    className="hover-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNavigateToPerformingCompany(subTrip.clientName);
                    }}
                  >
                    {subTrip.clientName}
                  </span>
                </td>
                <td>
                  <span
                    className="hover-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNavigateToPerformingCompany(
                        subTrip.performingCompanyName
                      );
                    }}
                  >
                    {subTrip.performingCompanyName || "-"}
                  </span>
                </td>
                <td>
                  {subTrip.shortRemarks ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${subTrip.lineCode}`}>
                          {subTrip.shortRemarks}
                        </Tooltip>
                      }
                    >
                      <span className="text-truncated">
                        {subTrip.shortRemarks.slice(0, 20)}
                        {subTrip.shortRemarks.length > 20 && "..."}
                      </span>
                    </OverlayTrigger>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {subTrip.netClientPrice > 0 ? (
                    <span className="font-weight-bold">
                      {formatNumberWithCommas(subTrip.netClientPrice)} ₪
                    </span>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
        </React.Fragment>
      );
    }

    // שורה רגילה (לא בנדל)
    // שורה רגילה (לא בנדל)
    const missingFields = getMissingFields(item);
    const numOfPassengers = item.stations.reduce(
      (total, station) => total + station.passengers.length,
      0
    );

    return (
      <tr
        key={item.lineCode}
        className={classNames("table-row custom-regular-background", {
          "table-info-static":
            item.performingCompanyName && item.isActive === "1",
          "custom-danger-background line-through":
            item.isActive === "0" ||
            item.lineDescription.includes("נסיעה בוטלה"),
          "error-color-class": missingFields.length > 0,
        })}
        onClick={() => handleRowClick(item.id)}
        style={{ cursor: "pointer" }}
      >
        <td>{formatDate(item.orderStartTime.split(" ")[0])}</td>
        <td>{item.orderEndTime.split(" ")[1]}</td>
        <td>{item.orderStartTime.split(" ")[1]}</td>
        <td>
          <div>
            {item.lineDescription.slice(0, 60)}
            {item.lineDescription.length > 60 && "..."}
          </div>
          <div className="text-muted">
            נוסעים:{" "}
            {item?.passQty && item?.passQty !== ""
              ? item.passQty
              : numOfPassengers > 0
              ? numOfPassengers
              : "-"}{" "}
            | תחנות: {item.stations.length} | סוג רכב: {item.orderCarType}
          </div>
          {missingFields.length > 0 && (
            <div className="text-danger">חסר: {missingFields.join(", ")}</div>
          )}
        </td>
        <td>
          <span
            className="hover-link"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateToPerformingCompany(item.clientName);
            }}
          >
            {item.clientName}
          </span>
        </td>
        <td>
          <span
            className="hover-link"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateToPerformingCompany(item.performingCompanyName);
            }}
          >
            {item.performingCompanyName || "-"}
          </span>
        </td>
        <td>
          {item.shortRemarks ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${item.lineCode}`}>
                  {item.shortRemarks}
                </Tooltip>
              }
            >
              <span className="text-truncated">
                {item.shortRemarks.slice(0, 20)}
                {item.shortRemarks.length > 20 && "..."}
              </span>
            </OverlayTrigger>
          ) : (
            "-"
          )}
        </td>
        <td>
          {item.netClientPrice > 0 ? (
            <span className="font-weight-bold">
              {formatNumberWithCommas(item.netClientPrice)} ₪
            </span>
          ) : (
            "-"
          )}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (filteredTrips.length > 0) {
      const processedTrips = groupAndProcessBundles(filteredTrips);
      setFilteredTrips(processedTrips);
    }
  }, [trips]);

  return (
    <div className="d-flex flex-column gap-3">
      <AutoRefreshToggle />
      <InactivityModal />
      <MiniDashboard searchParams={{ startDate, endDate }} />
      <SearchForm
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        carTypeFilter={carTypeFilter}
        setCarTypeFilter={setCarTypeFilter}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
        carTypes={carTypes}
        trips={trips}
        onSearch={handleSearch}
        updateUrlParams={updateUrlParams}
        fetchTrips={fetchTrips}
        setCurrentPage={setCurrentPage}
        fetchCompanies={fetchCompanies}
      />

      {filteredTrips?.length > 0 ? (
        <>
          <div className="custom-table-container">
            <div className="table-responsive">
              <Table responsive className="table-hover">
                <thead className="thead-light sticky-header">
                  <tr className="font-weight-bold">
                    <th>תאריך</th>
                    <th>
                      שעת<div>סיום</div>
                    </th>
                    <th>
                      שעת<div>התחלה</div>
                    </th>
                    <th>תיאור הקו</th>
                    <th>
                      חברה<div>מציעה</div>
                    </th>
                    <th>
                      חברה<div>מבצעת</div>
                    </th>
                    <th>
                      הערות<div>נסיעה</div>
                    </th>
                    <th>
                      מחיר<div>כולל מע״מ</div>
                    </th>
                  </tr>
                </thead>
                <tbody>{filteredTrips.map(renderTripRow)}</tbody>
              </Table>
            </div>
          </div>
          <PaginationComponent />
        </>
      ) : (
        displayTableState()
      )}
    </div>
  );
};

export default TripsTable;
