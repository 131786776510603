import React, { useState, useEffect } from "react";
import { Button, Table, Card } from "react-bootstrap";
import { fetchData } from "../../../../utils/fetchData";
import { useNavigate } from "react-router-dom";
import ClientBranchesModals from "./ClientBranchesModals";
import { useToast } from "../../../../context/ToastContext";
import { useLoading } from "../../../../context/LoadingContext";

const ClientBranchesSection = ({
  client,
  fetchClientDetails,
  setShowAddModal,
}) => {
  const { showLoading, hideLoading } = useLoading();
  const { showSuccessToast, showErrorToast } = useToast();
  const [branches, setBranches] = useState([]);
  const [editBranch, setEditBranch] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const userData = localStorage.getItem("userData");
  const token = userData ? JSON.parse(userData).token : null;

  useEffect(() => {
    fetchBranches();
  }, [client._id]);

  const fetchBranches = async () => {
    showLoading();
    try {
      if (!token) {
        showErrorToast("משתמש לא מאומת");
        return;
      }

      const response = await fetchData(
        {
          request: `branches/client/${client._id}`,
        },
        "GET",
        token
      );

      if (response.success) {
        setBranches(response.branches);
      }
    } catch (err) {
      showErrorToast("שגיאה בטעינת סניפים");
      console.error("Error fetching branches:", err);
    } finally {
      hideLoading();
      setLoading(false);
    }
  };

  const handleDeleteBranch = async (branchId) => {
    if (!window.confirm("האם אתה בטוח שברצונך למחוק את הסניף?")) return;

    showLoading();
    try {
      const response = await fetchData(
        {
          request: `branches/${branchId}`,
        },
        "DELETE",
        token
      );

      if (response.success) {
        showSuccessToast("הסניף נמחק בהצלחה");
        fetchBranches();
        fetchClientDetails();
      }
    } catch (err) {
      showErrorToast("אירעה שגיאה במחיקת הסניף");
    } finally {
      hideLoading();
    }
  };

  const handleEditBranch = async (branchData) => {
    if (
      !branchData.name ||
      !branchData.billingEmail ||
      !branchData.clientDriverCode
    ) {
      showErrorToast("יש למלא את כל השדות");
      return;
    }

    showLoading();
    try {
      const response = await fetchData(
        {
          request: `branches/${branchData._id}`,
          body: {
            name: branchData.name,
            billingEmail: branchData.billingEmail,
            clientDriverCode: branchData.clientDriverCode,
          },
        },
        "PUT",
        token
      );

      if (response.success) {
        showSuccessToast("הסניף עודכן בהצלחה");
        setShowEditModal(false);
        setEditBranch(null);
        fetchBranches();
        fetchClientDetails();
      }
    } catch (err) {
      showErrorToast(err.response?.data?.message || "אירעה שגיאה בעדכון הסניף");
    } finally {
      hideLoading();
    }
  };

  if (loading) return <div>טוען...</div>;

  return (
    <div className="w-100">
      <Card className="mb-4 border border-1">
        <Card.Header>
          <h2 className="font-weight-bold">סניפים</h2>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="primary" onClick={() => setShowAddModal(true)}>
              הוסף סניף חדש
            </Button>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="font-weight-bold">שם הסניף</th>
                <th className="font-weight-bold">עיר</th>
                <th className="font-weight-bold">מחוז</th>
                <th className="font-weight-bold">מייל לחשבוניות</th>
                <th className="font-weight-bold">קוד נהג</th>
                {/* <th className="font-weight-bold">פעולות</th> */}
              </tr>
            </thead>
            <tbody>
              {branches.map((branch) => (
                <tr key={branch._id}>
                  <td
                    onClick={() =>
                      navigate(`/dashboard/branches/${branch._id}`)
                    }
                    style={{ cursor: "pointer" }}
                    className="hover-link"
                  >
                    {branch.name}
                  </td>
                  <td>{branch.city || "-"}</td>
                  <td>{branch.state || "-"}</td>
                  <td>{branch.billingEmail}</td>
                  <td>{branch.clientDriverCode}</td>
                  {/* <td>
                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          setEditBranch(branch);
                          setShowEditModal(true);
                        }}
                      >
                        ערוך
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteBranch(branch._id)}
                      >
                        מחק
                      </Button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>

          <ClientBranchesModals
            show={showEditModal}
            onHide={() => {
              setShowEditModal(false);
              setEditBranch(null);
            }}
            onSave={handleEditBranch}
            initialData={
              editBranch || { name: "", billingEmail: "", clientDriverCode: "" }
            }
            title="עריכת סניף"
            saveButtonText="שמור שינויים"
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ClientBranchesSection;
