// components/ClientCardTripsSection.jsx
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ClientDetailsCardTripsItem from "./ClientDetailsCardTripsItem";
import { fetchData } from "../../../../utils/fetchData";
import { formatNumberWithCommas } from "../../../../utils/supportFunctions";

export default function ClientCardTripsSection({ client, context }) {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getStartAndEndDates = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
      startDate: start.toISOString().split("T")[0],
      endDate: end.toISOString().split("T")[0],
    };
  };

  useEffect(() => {
    fetchSummary();
  }, [client._id, context]);

  const fetchSummary = async () => {
    setLoading(true);
    try {
      const dataObj = {
        request:
          context === "client"
            ? `transactions/client/${client._id}/summary`
            : `transactions/branch/${client._id}/summary`,
      };
      const { startDate, endDate } = getStartAndEndDates();
      const userData = localStorage.getItem("userData");
      const token = userData ? JSON.parse(userData).token : null;

      if (!token) {
        throw new Error("Authentication token is missing.");
      }

      const params = {
        startDate,
        endDate,
        _t: Date.now(),
      };

      const response = await fetchData(dataObj, "GET", token, params);
      if (response?.summary) {
        setSummary(response.summary);
        setError(null);
      } else {
        setError("לא ניתן היה לטעון סיכום.");
      }
    } catch (err) {
      console.error("Error in fetchSummary:", err);
      setError(err.message || "שגיאה בטעינת סיכום.");
    } finally {
      setLoading(false);
    }
  };

  const getPlaceholder = () => "—";

  // פונקציה לפורמוט מספרים בטוח
  const formatSafeNumber = (value, defaultValue = 0) => {
    if (value === undefined || value === null || isNaN(value)) {
      return formatNumberWithCommas(defaultValue.toFixed(2));
    }
    return formatNumberWithCommas(Number(value).toFixed(2));
  };

  // פונקציה לחישוב הרווח בטוח
  const calculateSafeProfit = (revenue, fees) => {
    const safeRevenue = Number(revenue) || 0;
    const safeFees = Number(fees) || 0;
    return formatSafeNumber(safeRevenue - safeFees);
  };

  return (
    <Row>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שפורסמו"
          data={[
            {
              label: "כמות נסיעות",
              value: loading
                ? getPlaceholder()
                : summary?.totalPublishedTrips || 0,
            },
            {
              label: "נסיעות פתוחות",
              value: loading ? getPlaceholder() : summary?.openTrips || 0,
            },
            {
              label: "נסיעות סגורות",
              value: loading ? getPlaceholder() : summary?.closedTrips || 0,
            },
            {
              label: "נסיעות שבוטלו",
              value: loading ? getPlaceholder() : summary?.canceledTrips || 0,
            },
          ]}
        />
      </Col>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שנלקחו"
          tooltip="נסיעות שהלקוח הציע במערכת ולקוח אחר מבצע את הנסיעה עבורו"
          data={[
            {
              label: "כמות נסיעות",
              value: loading ? getPlaceholder() : summary?.totalTakenTrips || 0,
            },
            {
              label: "סכום",
              value: loading
                ? getPlaceholder()
                : formatSafeNumber(summary?.totalTakenRevenueWithVAT),
            },
            {
              label: "עמלה",
              value: loading
                ? getPlaceholder()
                : formatSafeNumber(summary?.totalTakenFeesWithVAT),
            },
          ]}
          totalCalculation={
            loading
              ? getPlaceholder()
              : calculateSafeProfit(
                  summary?.totalTakenRevenueWithVAT,
                  summary?.totalTakenFeesWithVAT
                )
          }
        />
      </Col>
      <Col md={4}>
        <ClientDetailsCardTripsItem
          title="נסיעות שבוצעו"
          tooltip="נסיעות שהלקוח מבצע עבור לקוח אחר"
          data={[
            {
              label: "כמות נסיעות",
              value: loading ? getPlaceholder() : summary?.performedTrips || 0,
            },
            {
              label: "סכום",
              value: loading
                ? getPlaceholder()
                : formatSafeNumber(summary?.totalPerformedRevenueWithVAT),
            },
            {
              label: "עמלה",
              value: loading
                ? getPlaceholder()
                : formatSafeNumber(summary?.totalPerformedFeesWithVAT),
            },
          ]}
          totalCalculation={
            loading
              ? getPlaceholder()
              : calculateSafeProfit(
                  summary?.totalPerformedRevenueWithVAT,
                  summary?.totalPerformedFeesWithVAT
                )
          }
        />
      </Col>
    </Row>
  );
}
