import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import { fetchData } from "../../../utils/fetchData";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastContext";
import { usePermissions } from "../../../context/usePermissions";

const DangerZone = ({ client, fetchClientDetails }) => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [loadingToggleAccess, setLoadingToggleAccess] = useState(false);
  const [loadingDeleteClient, setLoadingDeleteClient] = useState(false);
  const navigate = useNavigate();

  const handleDeleteClient = async () => {
    setLoadingDeleteClient(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/${client?._id}` },
        "DELETE",
        token
      );

      showSuccessToast(response.message);
      navigate("/dashboard/clients");
    } catch (error) {
      console.error("Error deleting client:", error);
      showErrorToast(error.message);
    } finally {
      setLoadingDeleteClient(false);
    }
  };

  const handleToggleAccess = async () => {
    setLoadingToggleAccess(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/toggle-access/${client?._id}` },
        "PUT",
        token
      );

      showSuccessToast(response.message);
      fetchClientDetails();
    } catch (error) {
      console.error(`Error toggling client access:`, error);
      showErrorToast(error.message);
    } finally {
      setLoadingToggleAccess(false);
    }
  };

  return (
    <div>
      <div className="bg-white text-danger">
        <BsExclamationTriangleFill className="me-2" />
        איזור רגיש
      </div>
      <div>
        <p>
          שים לב - במקרה של מחיקת לקוח לא יהיה ניתן לבטל פעולה זו, וכל המידע של
          הלקוח ימחק לצמיתות!
        </p>
        {hasPermission("clients.settings", "write") && (
          <Button
            variant={client.isActive ? "warning" : "success"}
            onClick={handleToggleAccess}
            disabled={loadingToggleAccess}
          >
            {loadingToggleAccess
              ? "מבצע פעולה..."
              : client.isActive
              ? "חסום גישה ללקוח"
              : "פתח גישה ללקוח"}
          </Button>
        )}
        {hasPermission("clients.settings", "delete") && (
          <Button
            variant="danger"
            className="ms-2"
            onClick={() => setIsAreYouSureOpen(true)}
            disabled={loadingDeleteClient}
          >
            {loadingDeleteClient ? "מוחק לקוח..." : "מחק לקוח"}
          </Button>
        )}
      </div>

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={() => setIsAreYouSureOpen(false)}
        handleApprove={handleDeleteClient}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך למחוק את הלקוח ${client?.name}? לא ניתן לבטל פעולה זו.`}
      />
    </div>
  );
};

export default DangerZone;
