import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { fetchData } from "../../../utils/fetchData";
import { useLoading } from "../../../context/LoadingContext";
import { useToast } from "../../../context/ToastContext";

import OrderDetailsHeaderSection from "./OrderDetailsHeaderSection";
import OrderDetailsRevenueSection from "./OrderDetailsRevenueSection";
import OrderDetailsContentSection from "./OrderDetailsContentSection";
import BackButton from "../../../components/BackButton/BackButton";
import NotesSection from "../../../components/NotesSection/NotesSection";

const TripDetails = () => {
  const { showLoading, hideLoading } = useLoading();
  const { showErrorToast } = useToast();
  const { id } = useParams();

  // Component state
  const [tripDetails, setTripDetails] = useState(null);

  useEffect(() => {
    // Fetch trip details from the server
    const fetchTripDetails = async () => {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData)?.token;

      showLoading();
      try {
        const dataObj = { request: `trip/${id}` };
        const response = await fetchData(dataObj, "GET", token);
        if (response?.trip) {
          setTripDetails(response?.trip);
        } else {
          showErrorToast("לא נמצאו פרטי הנסיעה");
        }
      } catch (err) {
        showErrorToast("שגיאה בטעינת פרטי הנסיעה");
        console.error("Fetch error:", err);
      } finally {
        hideLoading();
      }
    };

    fetchTripDetails();
  }, [id]); // רק id כ-dependency

  // Display loading state while fetching data
  if (!tripDetails) return null;

  return (
    <Container fluid className="p-4">
      <div className="d-flex gap-3 flex-column">
        <div className="d-flex gap-2 justify-content-start align-items-center">
          <BackButton />
          <h1 className="font-weight-bold">כרטיס נסיעה {tripDetails?.id}#</h1>
        </div>
        <OrderDetailsHeaderSection tripDetails={tripDetails} />
        <OrderDetailsRevenueSection tripDetails={tripDetails} />
        <Row>
          <Col md={8}>
            <OrderDetailsContentSection tripDetails={tripDetails} />
          </Col>
          <Col md={4}>
            <NotesSection id={tripDetails.id} context="trip" />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TripDetails;
