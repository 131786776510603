import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TripsByBranch = ({ data = [], showAllBranches, setShowAllBranches }) => {
  const navigate = useNavigate();

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("he-IL", {
      style: "currency",
      currency: "ILS",
      maximumFractionDigits: 0,
    }).format(amount || 0);
  };

  const handleBranchClick = (branchId) => {
    navigate(`/dashboard/branches/${branchId}`);
  };

  const displayData = showAllBranches ? data : data.slice(0, 5);

  return (
    <Card className="border border-1">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span className="fw-bold">נסיעות לפי סניפים</span>
        {data.length > 5 && (
          <Button
            variant="link"
            onClick={() => setShowAllBranches(!showAllBranches)}
            className="text-decoration-none"
          >
            {showAllBranches ? "הצג פחות" : `הצג הכל (${data.length})`}
          </Button>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="py-3">סניף</th>
                <th className="text-center py-3">נסיעות שפורסמו</th>
                <th className="text-center py-3">נסיעות שבוצעו</th>
                <th className="text-center py-3">הכנסות מפרסום</th>
                <th className="text-center py-3">הכנסות מביצוע</th>
              </tr>
            </thead>
            <tbody>
              {displayData.map((branch) => (
                <tr key={branch._id}>
                  <td>
                    <Button
                      variant="link"
                      className="hover-link text-black p-0 text-start "
                      onClick={() => handleBranchClick(branch._id)}
                    >
                      {branch.name}
                    </Button>
                  </td>
                  <td className="text-center">{branch.publishedTrips}</td>
                  <td className="text-center">{branch.performedTrips}</td>
                  <td className="text-center" dir="ltr">
                    {formatCurrency(branch.publisherRevenue)}
                  </td>
                  <td className="text-center" dir="ltr">
                    {formatCurrency(branch.performerRevenue)}
                  </td>
                </tr>
              ))}
              {displayData.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    אין נתונים זמינים
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TripsByBranch;
