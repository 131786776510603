// AdminsPage.jsx
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { useLoading } from "../../context/LoadingContext";
import { usePermissions } from "../../context/usePermissions";
import CreateAdminModal from "./CreateAdminModal";
import { fetchData } from "../../utils/fetchData";
import AdminsTable from "./AdminTable";
import { ADMIN_SECTIONS, PAGES } from "../../config/permissions";
import { withPermissions } from "../../components/hoc/withPermissions";

const AdminsPage = () => {
  const { showLoading, hideLoading } = useLoading();
  const { hasPermission, loading } = usePermissions();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [initialState] = useState(() => ({
    currentPage: parseInt(searchParams.get("page") || "1"),
    searchTerm: searchParams.get("search") || "",
    sortField: searchParams.get("sortField") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  }));

  const updateUrlParams = (updates, triggerSearch = false) => {
    const newParams = Object.fromEntries(searchParams.entries());

    Object.keys(updates).forEach((key) => {
      if (updates[key]) {
        newParams[key] = updates[key];
      } else {
        delete newParams[key];
      }
    });

    if (triggerSearch) {
      setSearchParams(newParams, { replace: true });
    }

    return newParams;
  };

  const fetchAdmins = async (page = 1, params = {}) => {
    try {
      showLoading();
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const queryParams = {
        page,
        limit: 25,
        ...params,
      };

      const requestObject = { request: "admins" };
      const response = await fetchData(
        requestObject,
        "GET",
        token,
        queryParams
      );

      if (response.success) {
        setAdmins(response.admins);
        setTotalPages(response.totalPages);
        setCurrentPage(response.currentPage);
      } else {
        throw new Error(response.message || "שגיאה בטעינת המשתמשים");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      hideLoading();
    }
  };

  // Load data initially
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    fetchAdmins(params.page || 1, params);
  }, [searchParams]);

  if (loading) return <div>טוען...</div>;

  return (
    <Container fluid className="p-4">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <h2 className="font-weight-bold">משתמשי אדמין</h2>
      </div>

      <AdminsTable
        fetchAdmins={fetchAdmins}
        admins={admins}
        totalPages={totalPages}
        loading={loading}
        initialState={initialState}
        updateUrlParams={updateUrlParams}
        setIsOpen={setIsOpen}
        setAdmin={setAdmin}
      />

      {hasPermission(ADMIN_SECTIONS.MANAGE) && (
        <CreateAdminModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fetchAdmins={fetchAdmins}
          admin={admin}
          setAdmin={setAdmin}
        />
      )}
    </Container>
  );
};

export default withPermissions(AdminsPage, PAGES.ADMINS);
