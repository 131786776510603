import React from "react";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HeaderSection = ({
  dateRange,
  setDateRange,
  showCustomRange,
  setShowCustomRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  return (
    <Row className="mb-4 align-items-center">
      <Col>
        <ButtonGroup>
          <Button
            variant={dateRange === "today" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("today");
              setShowCustomRange(false);
            }}
          >
            היום
          </Button>
          <Button
            variant={dateRange === "tomorrow" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("tomorrow");
              setShowCustomRange(false);
            }}
          >
            מחר
          </Button>
          <Button
            variant={dateRange === "nextWeek" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("nextWeek");
              setShowCustomRange(false);
            }}
          >
            שבוע הבא
          </Button>
          <Button
            variant={dateRange === "week" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("week");
              setShowCustomRange(false);
            }}
          >
            7 ימים אחרונים
          </Button>
          <Button
            variant={dateRange === "month" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("month");
              setShowCustomRange(false);
            }}
          >
            החודש
          </Button>
          <Button
            variant={dateRange === "fullMonth" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("fullMonth");
              setShowCustomRange(false);
            }}
          >
            כל החודש
          </Button>
          <Button
            variant={dateRange === "custom" ? "primary" : "outline-primary"}
            onClick={() => {
              setDateRange("custom");
              setShowCustomRange(true);
            }}
          >
            טווח מותאם
          </Button>
        </ButtonGroup>
      </Col>
      {showCustomRange && (
        <Col xs="auto" className="d-flex align-items-center gap-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="תאריך התחלה"
            className="form-control"
            dateFormat="dd/MM/yyyy"
          />
          <span>-</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="תאריך סיום"
            className="form-control"
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      )}
    </Row>
  );
};

export default HeaderSection;
