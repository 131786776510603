import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useToast } from "../../context/ToastContext";
import { fetchData } from "../../utils/fetchData";

const CreateAdminModal = ({ isOpen, setIsOpen, fetchAdmins }) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Form states
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const defaultPermissions = {
    clients: ["read"],
    trips: ["read"],
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 10) return;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      showErrorToast("הסיסמאות אינן תואמות");
      return;
    }

    try {
      const token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetchData(
        {
          request: "admins",
          ...formData,
          permissions: defaultPermissions,
        },
        "POST",
        token
      );

      if (response.success) {
        showSuccessToast("המשתמש נוצר בהצלחה");
        handleClose();
        fetchAdmins();
      }
    } catch (error) {
      // הטיפול בשגיאה צריך להשתנות כאן
      showErrorToast(
        error.response?.data?.message || error.message || "שגיאה ביצירת המשתמש"
      );
    }
  };

  const handleClose = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    });
    setValidated(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
    setIsOpen(false);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} size="lg" dir="rtl">
      <Modal.Header>
        <Modal.Title>יצירת משתמש חדש</Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>שם פרטי</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  שדה חובה
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>שם משפחה</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  שדה חובה
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>אימייל</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  נא להזין כתובת אימייל תקינה
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>טלפון</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  pattern="[0-9]{10}"
                  maxLength={10}
                />
                <Form.Control.Feedback type="invalid">
                  נא להזין מספר טלפון תקין (10 ספרות)
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>סיסמה</Form.Label>
                <div className="d-flex gap-2">
                  <Form.Control
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    minLength={6}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {showPassword ? "הסתר סיסמה" : "הצג סיסמה"}
                  </Button>
                </div>
                <Form.Control.Feedback type="invalid">
                  סיסמה חייבת להכיל לפחות 6 תווים
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>אימות סיסמה</Form.Label>
                <div className="d-flex gap-2">
                  <Form.Control
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {showConfirmPassword ? "הסתר סיסמה" : "הצג סיסמה"}
                  </Button>
                </div>
                <Form.Control.Feedback type="invalid">
                  שדה חובה
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ביטול
          </Button>
          <Button variant="primary" type="submit">
            יצירת משתמש
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateAdminModal;
