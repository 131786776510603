import React, { useEffect, useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../utils/fetchData";
import HeaderSection from "./HeaderSection";
import { formatNumberWithCommas } from "../../utils/supportFunctions";

const statusConfig = [
  {
    type: "published",
    label: "נסיעות שפורסמו",
    getCount: (data) => data.publishedTrips || 0,
    getAmount: (data) => data.publishedTripsAmount || 0,
    colorClass: "bg-primary",
    icon: "📢",
  },
  {
    type: "taken",
    label: "נסיעות שנלקחו",
    getCount: (data) => data.takenTrips || 0,
    getAmount: (data) => data.takenTripsAmount || 0,
    colorClass: "bg-warning",
    icon: "🚗",
  },
  {
    type: "inProgress",
    label: "נסיעות בתהליך",
    getCount: (data) => data.inProgress || 0,
    getAmount: (data) => data.inProgressTripsAmount || 0,
    colorClass: "bg-dark",
    icon: "🚗",
  },
  {
    type: "performed",
    label: "נסיעות שהושלמו",
    getCount: (data) => data.performedTrips || 0,
    getAmount: (data) => data.performedTripsAmount || 0,
    colorClass: "bg-success",
    icon: "✅",
  },
  {
    type: "canceled",
    label: "נסיעות שבוטלו",
    getCount: (data) => data.canceledTrips || 0,
    getAmount: (data) => data.canceledTripsAmount || 0,
    colorClass: "bg-secondary",
    icon: "❌",
  },
  {
    type: "open",
    label: "נסיעות פתוחות",
    getCount: (data) => data.openTrips || 0,
    getAmount: (data) => data.openTripsAmount || 0,
    colorClass: "bg-danger",
    icon: "⭕",
  },
];

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("he-IL", {
    style: "currency",
    currency: "ILS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount || 0);
};

const StatusCard = ({ status, analytics }) => {
  const count = status.getCount(analytics);
  const amount = status.getAmount(analytics);

  return (
    <div
      className={`${status.colorClass} bg-opacity-90 rounded p-4 text-white hover:bg-opacity-100 transition-all`}
    >
      <div className="d-flex align-items-center gap-2 mb-2">
        <span>{status.icon}</span>
        <h5 className="fs-5 fw-medium m-0">{status.label}</h5>
      </div>
      <p className="fs-2 fw-bold m-0">{count}</p>
      <p className="small opacity-90 mt-1">{formatNumberWithCommas(amount)}</p>
    </div>
  );
};

const formatDate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

const MiniDashboard = () => {
  const [analytics, setAnalytics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Date range states
  const [dateRange, setDateRange] = useState(() => {
    return localStorage.getItem("dashboardDateRange") || "today";
  });
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getDateRangeParams = useCallback(() => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);

    switch (dateRange) {
      case "today":
        return { startDate: now, endDate: now };
      case "tomorrow":
        return { startDate: tomorrow, endDate: tomorrow };
      case "nextWeek":
        const nextWeekStart = new Date(now);
        const nextWeekEnd = new Date(nextWeekStart);
        nextWeekEnd.setDate(nextWeekStart.getDate() + 7);
        return { startDate: nextWeekStart, endDate: nextWeekEnd };
      case "week":
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return { startDate: weekAgo, endDate: now };
      case "month":
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        return { startDate: monthStart, endDate: now };
      case "fullMonth":
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return { startDate: firstDay, endDate: lastDay };
      case "custom":
        if (startDate && endDate) {
          return { startDate, endDate };
        }
        return null;
      default:
        return { startDate: now, endDate: now };
    }
  }, [dateRange, startDate, endDate]);

  const fetchAnalytics = useCallback(async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        navigate("/login");
        return;
      }

      const token = JSON.parse(userData).token;
      const dates = getDateRangeParams();

      if (!dates) return;

      const params = new URLSearchParams();
      params.append("startDate", formatDate(dates.startDate));
      params.append("endDate", formatDate(dates.endDate));

      const dataObj = {
        request: "trip/dashboard/analytics",
      };
      if (params.toString()) {
        dataObj.request += `?${params.toString()}`;
      }

      const response = await fetchData(dataObj, "GET", token, {}, navigate);

      if (response?.success && response?.data?.tripsAnalytics) {
        setAnalytics(response.data.tripsAnalytics);
        setError(null);
      } else {
        throw new Error(response?.message || "Failed to fetch analytics");
      }
    } catch (error) {
      console.error("MiniDashboard fetch error:", error);
      setError("שגיאה בטעינת נתוני לוח הבקרה");
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, startDate, endDate, navigate]);

  useEffect(() => {
    if (dateRange !== "custom" || (startDate && endDate)) {
      fetchAnalytics();
    }
  }, [fetchAnalytics]);

  if (isLoading) {
    return (
      <div className="bg-white rounded shadow p-4 mb-4">
        <div className="d-flex justify-content-center align-items-center p-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">טוען...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded shadow p-4 mb-4">
        <div className="text-center text-danger">{error}</div>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="bg-white rounded shadow p-4 mb-4">
        <div className="text-center">לא נמצאו נתונים</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded shadow p-4 mb-4">
      <HeaderSection
        dateRange={dateRange}
        setDateRange={setDateRange}
        showCustomRange={showCustomRange}
        setShowCustomRange={setShowCustomRange}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      <div className="row g-4">
        {statusConfig.map((status) => (
          <div key={status.type} className="col-12 col-sm-6 col-md-4 col-lg-2">
            <StatusCard status={status} analytics={analytics} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MiniDashboard;
