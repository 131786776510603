import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { useToast } from "../../context/ToastContext";

export const CreateOrganizerModal = ({
  isOpen,
  setIsOpen,
  fetchOrganizers,
  organizer,
  setOrganizer,
  client,
}) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  useEffect(() => {
    if (organizer) {
      setFirstName(organizer.firstName);
      setLastName(organizer.lastName);
      setPhoneNumber(organizer.phoneNumber);
    } else {
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
    }
  }, [organizer]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (organizer) {
      await updateOrganizer();
    } else {
      await addNewOrganizer();
    }
  };

  const validateForm = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }

    if (!lastName) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      setPhoneNumberError(true);
      valid = false;
    } else {
      setPhoneNumberError(false);
    }

    return valid;
  };

  const addNewOrganizer = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestData = client.parentClient
        ? {
            request: "organizers/register",
            firstName,
            lastName,
            phoneNumber,
            branchId: client._id,
          }
        : {
            request: "organizers/register",
            firstName,
            lastName,
            phoneNumber,
            clientId: client._id,
          };

      const response = await fetchData(requestData, "POST", token);

      if (response.success) {
        showSuccessToast("הסדרן נוסף בהצלחה");
        setIsOpen(false);
        fetchOrganizers();
        resetFormFields();
      } else {
        throw new Error(response.message || "שגיאה ביצירת סדרן");
      }
    } catch (err) {
      console.error("Error adding new organizer:", err.message);
      showErrorToast(
        err.response?.data?.message || err.message || "שגיאה ביצירת סדרן"
      );
    }
  };

  const updateOrganizer = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `organizers/${organizer._id}`,

        firstName,
        lastName,
        phoneNumber,
        parentClient: client.parentClient
          ? client.parentClient._id
          : client._id,
        branch: client.parentClient ? client._id : null,
      };

      await fetchData(requestObject, "PUT", token);
      showSuccessToast("הסדרן נערך בהצלחה");
      fetchOrganizers();
      closeModal();
    } catch (err) {
      console.error(err.message);
      showErrorToast("לא ניתן לערוך את הסדרן");
      fetchOrganizers();
      closeModal();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    resetFormFields();
  };

  const resetFormFields = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setFirstNameError(false);
    setLastNameError(false);
    setPhoneNumberError(false);
    setOrganizer(null);
  };

  return (
    <Modal show={isOpen} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{organizer ? "עריכת סדרן" : "יצירת סדרן חדש"}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="create_organizer_modal--wrapper">
            <Form.Group className="mb-3" controlId="firstName">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  שם פרטי
                </span>
              </div>
              <Form.Control
                type="text"
                value={firstName}
                placeholder="הכנס את שם הפרטי"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError(false);
                }}
                required
                className={firstNameError ? "input-error" : "input-regular"}
              />
              {firstNameError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastName">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  שם משפחה
                </span>
              </div>
              <Form.Control
                type="text"
                value={lastName}
                placeholder="הכנס את שם המשפחה"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError(false);
                }}
                required
                className={lastNameError ? "input-error" : "input-regular"}
              />
              {lastNameError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="phoneNumber">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  טלפון
                </span>
              </div>
              <Form.Control
                type="text"
                value={phoneNumber}
                placeholder="הכנס מספר טלפון"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setPhoneNumberError(false);
                }}
                required
                maxLength={10}
                className={phoneNumberError ? "input-error" : "input-regular"}
              />
              {phoneNumberError && (
                <span className="text-danger text-sm">
                  מספר טלפון צריך להיות באורך 10 ספרות
                </span>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            סגירה
          </Button>
          <Button variant="success" type="submit">
            המשך
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
