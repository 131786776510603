import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TripsByClient = ({ data = [], showAllClients, setShowAllClients }) => {
  const navigate = useNavigate();

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("he-IL", {
      style: "currency",
      currency: "ILS",
      maximumFractionDigits: 0,
    }).format(amount || 0);
  };

  const handleClientClick = (clientId) => {
    navigate(`/dashboard/clients/${clientId}`);
  };

  // הצג את כל הנתונים או רק 5 הראשונים בהתאם למצב
  const displayData = showAllClients ? data : data.slice(0, 5);

  return (
    <Card className="border border-1">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span className="fw-bold">פעילות לפי חברות</span>
        {data.length > 5 && (
          <Button
            variant="link"
            onClick={() => setShowAllClients(!showAllClients)}
            className="text-decoration-none"
          >
            {showAllClients ? "הצג פחות" : `הצג הכל (${data.length})`}
          </Button>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="py-3">חברה</th>
                <th className="text-center py-3">נסיעות שפורסמו</th>
                <th className="text-center py-3">נסיעות שבוצעו</th>
                <th className="text-center py-3">הכנסות מפרסום</th>
                <th className="text-center py-3">הכנסות מביצוע</th>
              </tr>
            </thead>
            <tbody>
              {displayData.map((client) => (
                <tr key={client._id}>
                  <td>
                    <Button
                      variant="link"
                      className="hover-link text-black p-0 text-start "
                      onClick={() => handleClientClick(client._id)}
                    >
                      {client.name}
                    </Button>
                  </td>
                  <td className="text-center">{client.publishedTrips}</td>
                  <td className="text-center">{client.performedTrips}</td>
                  <td className="text-center" dir="ltr">
                    {formatCurrency(client.publisherRevenue)}
                  </td>
                  <td className="text-center" dir="ltr">
                    {formatCurrency(client.performerRevenue)}
                  </td>
                </tr>
              ))}
              {displayData.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    אין נתונים זמינים
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TripsByClient;
