import React, { useEffect, useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { PAGES } from "../../config/permissions";
import { withPermissions } from "../../components/hoc/withPermissions";

const ActivitiesPage = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [copySuccess, setCopySuccess] = useState("");

  const renderPagination = () => {
    const maxVisiblePages = 5; // Number of pages to show before and after current page
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if we're near the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // Always add first page
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) pageNumbers.push("...");
    }

    // Add pages in the middle
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Always add last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return (
      <Pagination className="justify-content-center mb-5 mt-3">
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <Pagination.Ellipsis key={`ellipsis-${index}`} />
          ) : (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </Pagination.Item>
          )
        )}

        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    );
  };

  const fetchActivities = async (page) => {
    setLoading(true);
    const token = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).token
      : null;

    if (!token) {
      console.error("No token found");
      setLoading(false);
      return;
    }

    try {
      const response = await fetchData(
        {
          request: `activities?page=${page}&limit=20`,
        },
        "GET",
        token
      );

      setActivities(response.activities || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  const toggleRow = (activityId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(activityId)) {
        newSet.delete(activityId);
      } else {
        newSet.add(activityId);
      }
      return newSet;
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const parseJsonString = (str) => {
    try {
      // If it's a JSON string within a string, parse twice
      if (typeof str === "string" && str.startsWith('"') && str.endsWith('"')) {
        const unescaped = JSON.parse(str); // First parse
        return JSON.parse(unescaped); // Second parse
      }

      // If it's a standard JSON string
      if (typeof str === "string") {
        return JSON.parse(str);
      }

      return str; // Return as-is if not a string
    } catch (e) {
      console.error("Error parsing JSON:", e.message);
      return `Error parsing JSON: ${e.message}`; // Return the error message as a fallback
    }
  };

  const handleCopy = async (content) => {
    try {
      let textToCopy;
      if (typeof content === "string") {
        // אם זה JSON בתוך מחרוזת, נפרסר אותו קודם
        const parsed = parseJsonString(content);
        textToCopy = JSON.stringify(parsed, null, 2);
      } else {
        textToCopy = JSON.stringify(content, null, 2);
      }

      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess("הועתק!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
      setCopySuccess("שגיאה בהעתקה");
    }
  };

  const renderJson = (content) => {
    const parsed = parseJsonString(content);
    return JSON.stringify(parsed, null, 2);
  };

  const renderActivityDetails = (activity) => {
    if (!expandedRows.has(activity._id)) return null;

    return (
      <tr>
        <td colSpan="5" className="details-cell">
          <div className="details-content">
            {/* בבקשת REQUEST נציג רק IP ואת תוכן הבקשה */}
            {activity.type === "REQUEST" && (
              <>
                <div className="ip-section">
                  <p>
                    <strong>IP:</strong> {activity.ip}
                  </p>
                </div>
                {activity.requestBody && (
                  <div>
                    <div className="content-header">
                      <strong>Request Body:</strong>
                      <button
                        onClick={() => handleCopy(activity.requestBody)}
                        className="copy-button"
                      >
                        העתק {copySuccess && "✓"}
                      </button>
                    </div>
                    <pre className="json-content">
                      {renderJson(activity.requestBody)}
                    </pre>
                  </div>
                )}
              </>
            )}

            {/* בתשובת RESPONSE נציג את כל הפרטים */}
            {activity.type === "RESPONSE" && (
              <>
                <div className="response-details">
                  <div className="user-info">
                    <p>
                      <strong>User:</strong>{" "}
                      {activity.user?.name || "Anonymous"}
                    </p>
                    {activity.user?.role && (
                      <p>
                        <strong>Role:</strong> {activity.user.role}
                      </p>
                    )}
                    {activity.user?.company && (
                      <p>
                        <strong>Company:</strong> {activity.user.company}
                      </p>
                    )}
                  </div>
                  <div className="technical-info">
                    <p>
                      <strong>Status Code:</strong> {activity.statusCode}
                    </p>
                    <p>
                      <strong>Response Time:</strong> {activity.responseTime}ms
                    </p>
                    <p>
                      <strong>IP:</strong> {activity.ip}
                    </p>
                  </div>
                </div>
                {activity.responseBody && (
                  <div>
                    <div className="content-header">
                      <strong>Response Body:</strong>
                      <button
                        onClick={() => handleCopy(activity.responseBody)}
                        className="copy-button"
                      >
                        העתק {copySuccess && "✓"}
                      </button>
                    </div>
                    <pre className="json-content">
                      {renderJson(activity.responseBody)}
                    </pre>
                  </div>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Container>
      <h2 className="my-4">System Activity Log</h2>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Type</th>
                  <th>Method</th>
                  <th>Endpoint</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((activity) => (
                  <React.Fragment key={activity._id}>
                    <tr
                      onClick={() => toggleRow(activity._id)}
                      className={`
                        activity-row 
                        ${
                          activity.type === "REQUEST"
                            ? "request-row"
                            : "response-row"
                        }
                        ${expandedRows.has(activity._id) ? "expanded" : ""}
                      `}
                    >
                      <td>{new Date(activity.timestamp).toLocaleString()}</td>
                      <td>{activity.type}</td>
                      <td>{activity.method}</td>
                      <td>{activity.endpoint}</td>
                      <td>{activity.statusCode || "-"}</td>
                    </tr>
                    {renderActivityDetails(activity)}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          {renderPagination()}
        </>
      )}
      <style>
        {`
    .activity-row {
      cursor: pointer;
    }
    .request-row {
      border-left: 4px solid #dc3545;
    }
    .response-row {
      border-left: 4px solid #28a745;
    }
    .expanded {
      background-color: #f8f9fa;
    }
    .details-cell {
      padding: 0;
    }
    .details-content {
      padding: 1rem;
      background-color: #fff;
    }
    .json-content {
      background-color: #f8f9fa;
      padding: 1rem;
      border-radius: 4px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      white-space: pre-wrap;
      word-break: break-word;
      font-family: monospace;
      font-size: 14px;
      line-height: 1.5;
      border: 1px solid #e9ecef;
      text-align: left;
      direction: ltr;
    }
    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0;
    }
    .copy-button {
      background-color: #f8f9fa;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      padding: 4px 12px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s;
      margin-right: 1rem;
    }
    .copy-button:hover {
      background-color: #e9ecef;
    }
  `}
      </style>
    </Container>
  );
};

export default withPermissions(ActivitiesPage, PAGES.ACTIVITIES);
