import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Pagination, Form } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { useToast } from "../../context/ToastContext";
import { usePermissions } from "../../context/usePermissions";
import { ADMIN_SECTIONS } from "../../config/permissions";

const AdminsTable = ({
  setIsOpen,
  admins,
  totalPages,
  initialState,
  updateUrlParams,
  setAdmin,
  fetchAdmins,
}) => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToast();
  const token = JSON.parse(localStorage.getItem("userData")).token;
  const { hasPermission } = usePermissions();

  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm || "");
  const [sortConfig, setSortConfig] = useState({
    field: initialState.sortField || "",
    order: initialState.sortOrder || "",
  });
  const [pendingSearchTerm, setPendingSearchTerm] = useState(searchTerm);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage || 1);

  // Format date function
  const formatDate = (dateString) => {
    if (!dateString) return "לא זמין";
    const date = new Date(dateString);
    return date.toLocaleString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Check if the admin is active
  const isRecentlyActive = (lastActive) => {
    if (!lastActive) return false;
    const lastActiveTime = new Date(lastActive).getTime();
    const now = Date.now();
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds
    return now - lastActiveTime <= fifteenMinutes;
  };

  const getStatusCircle = (lastActive) => {
    const isActive = isRecentlyActive(lastActive);
    return (
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          marginLeft: "8px",
          backgroundColor: isActive ? "green" : "red",
        }}
        title={isActive ? "Active recently" : "Inactive"}
      />
    );
  };

  const handleSort = (field) => {
    let order = "asc";
    if (sortConfig.field === field) {
      if (sortConfig.order === "asc") order = "desc";
      else if (sortConfig.order === "desc") {
        field = "";
        order = "";
      }
    }

    setSortConfig({ field, order });
    updateUrlParams(
      {
        sortField: field,
        sortOrder: order,
        page: 1,
      },
      true
    );
  };

  const handleSearch = (e) => {
    e?.preventDefault();
    setSearchTerm(pendingSearchTerm);
    updateUrlParams(
      {
        search: pendingSearchTerm || null,
        page: 1,
      },
      true
    );
  };

  const handleClearAllFilters = () => {
    setPendingSearchTerm("");
    setSearchTerm("");
    updateUrlParams(
      {
        search: null,
        page: 1,
      },
      true
    );
  };

  const handleDelete = async (adminId) => {
    if (window.confirm("האם אתה בטוח שברצונך למחוק את המשתמש?")) {
      try {
        const response = await fetchData(
          { request: `admins/toggle-status/${adminId}` },
          "PUT",
          token
        );
        if (response.success) {
          showSuccessToast("המשתמש נמחק בהצלחה");
          fetchAdmins();
        }
      } catch (error) {
        showErrorToast(error.response?.data?.message || "שגיאה במחיקת המשתמש");
      }
    }
  };

  const getSortIcon = (field) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === "asc" ? " ▲" : " ▼";
  };

  const PaginationComponent = () => {
    const maxPages = 5;
    const itemsPerPage = 25;

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      updateUrlParams({ page: pageNumber }, true);
    };

    const items = [
      <Pagination.First
        key="first"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />,
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />,
    ];

    const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    const endPage = Math.min(totalPages, startPage + maxPages - 1);

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    items.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />,
      <Pagination.Last
        key="last"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    );

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Pagination>{items}</Pagination>
          <div className="mx-3 d-flex justify-content-center align-items-center mt-2">
            <span className="text-muted">
              מציג {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, totalPages * itemsPerPage)}{" "}
              מתוך {totalPages * itemsPerPage} תוצאות
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderActionButtons = (admin) => {
    return (
      <div className="d-flex gap-2">
        {hasPermission(ADMIN_SECTIONS.MANAGE) && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => navigate(`/dashboard/admins/edit/${admin._id}`)}
          >
            ערוך
          </Button>
        )}
        {hasPermission(ADMIN_SECTIONS.MANAGE) && (
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(admin._id)}
          >
            מחק
          </Button>
        )}
      </div>
    );
  };

  const renderTable = () => (
    <div className="table-container">
      {admins.length > 0 ? (
        <div className="table-responsive">
          <Table responsive className="table-hover">
            <thead className="thead-light sticky-header">
              <tr>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("firstName")}
                >
                  שם מלא {getSortIcon("firstName")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("email")}
                >
                  אימייל {getSortIcon("email")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("phoneNumber")}
                >
                  טלפון {getSortIcon("phoneNumber")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("lastActive")}
                >
                  פעילות אחרונה {getSortIcon("lastActive")}
                </th>
                <th className="font-weight-medium">פעולות</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin) => (
                <tr key={admin._id} className="table-row">
                  <td>
                    {admin.firstName} {admin.lastName}{" "}
                    {getStatusCircle(admin.lastActive)}
                  </td>
                  <td>{admin.email}</td>
                  <td>{admin.phoneNumber}</td>
                  <td>{formatDate(admin.lastActive)}</td>
                  <td>{renderActionButtons(admin)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="no-data-message">אין נתונים להצגה</div>
      )}
    </div>
  );

  return (
    <Fragment>
      <div className="custom-table-container">
        <div className="table-header d-flex flex-column gap-4">
          <div className="d-flex flex-row justify-content-between">
            <span className="lead font-weight-bold">משתמשי אדמין רשומים</span>
            {hasPermission(ADMIN_SECTIONS.MANAGE) && (
              <Button
                className="table-btn"
                variant="primary"
                onClick={() => {
                  setAdmin(null);
                  setIsOpen(true);
                }}
              >
                יצירת משתמש חדש
              </Button>
            )}
          </div>

          <Row className="justify-content-center align-items-center">
            <Col md={8}>
              <form onSubmit={handleSearch}>
                <div className="d-flex gap-2">
                  <input
                    type="text"
                    placeholder="חיפוש לפי שם, אימייל או טלפון..."
                    value={pendingSearchTerm}
                    onChange={(e) => setPendingSearchTerm(e.target.value)}
                    className="form-control"
                  />
                </div>
              </form>
            </Col>
            <Col md={2}>
              <Button type="submit" variant="primary" onClick={handleSearch}>
                חיפוש
              </Button>
            </Col>
            <Col xs={12} md={2} className="d-flex justify-content-center">
              <Button
                variant="link"
                className="p-0 text-decoration-underline border-0 shadow-none"
                onClick={handleClearAllFilters}
              >
                נקה הכל
              </Button>
            </Col>
          </Row>
        </div>

        {renderTable()}

        {admins.length > 0 && totalPages > 1 && <PaginationComponent />}
      </div>
    </Fragment>
  );
};

export default AdminsTable;
