import { useState, useEffect, createContext, useContext } from "react";
import { fetchData } from "../utils/fetchData";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("userData"))?.token;
        if (!token) {
          setLoading(false);
          return;
        }

        const response = await fetchData(
          { request: "admins/current" },
          "GET",
          token
        );
        if (response.success) {
          setPermissions(response.permissions);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const hasPermission = (page, permission = "read") => {
    if (!permissions || typeof page !== "string") {
      return false;
    }

    if (permissions[page]?.includes(permission)) {
      return true;
    }

    if (page.includes(".")) {
      return permissions[page]?.includes(permission) || false;
    }
    return false;
  };

  return (
    <PermissionsContext.Provider
      value={{ permissions, loading, hasPermission, setPermissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};
