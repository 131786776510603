import React from "react";
import { Card } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from "recharts";

const PieChartSummary = ({ data = {}, getDateRangeDisplay }) => {
  const COLORS = ["#dc3545", "#FFBB28", "#198754", "#6c757d"];

  // Define the mapping between status and Hebrew labels
  const hebrewNames = {
    waiting: "פתוחות",
    approved: "נלקחו",
    completed: "בוצעו והסתיימו",
    canceled: "בוטלו",
  };

  // Convert the data to a format suitable for the PieChart
  const pieChartData = Object.keys(hebrewNames).map((status) => ({
    name: hebrewNames[status],
    value: data.byStatus?.[status] || 0,
  }));

  // Render the custom legend
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        className="recharts-default-legend mx-4"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                backgroundColor: entry.color,
                width: "12px",
                height: "12px",
                marginRight: "5px",
                borderRadius: "50%",
              }}
            ></div>
            <span className="mx-1">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card className="h-100 border border-1">
      <Card.Header className="font-weight-bold">
        <span>התפלגות נסיעות {getDateRangeDisplay()}</span>
      </Card.Header>
      <Card.Body>
        <div style={{ height: "400px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieChartSummary;
