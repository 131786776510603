import React from "react";
import DangerZone from "../DangerZone";

const ClientSettingsTab = ({ client, fetchClientDetails }) => {
  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <DangerZone client={client} fetchClientDetails={fetchClientDetails} />
    </div>
  );
};

export default ClientSettingsTab;
