import React, { useState, useEffect } from "react";
import { Form, Button, Table, Alert, Row, Col } from "react-bootstrap";
import { fetchData } from "../../../../utils/fetchData";
import { useToast } from "../../../../context/ToastContext";

export default function ClientCancellationRulesTab({
  client,
  fetchClientDetails,
  context,
}) {
  const [isUsingDefault, setIsUsingDefault] = useState(
    client.cancellationRules?.isUsingDefault ?? true
  );
  const [timeRanges, setTimeRanges] = useState(
    client.cancellationRules?.timeRanges ?? []
  );
  const [newRange, setNewRange] = useState({
    startTime: "",
    endTime: "",
    cancelMinutesBefore: "",
  });
  const [defaultRules, setDefaultRules] = useState(null);
  const [useGlobalTime, setUseGlobalTime] = useState(false);
  const [globalCancelTime, setGlobalCancelTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showSuccessToast } = useToast();

  useEffect(() => {
    const fetchDefaultRules = async () => {
      setLoading(true);
      try {
        const userData = localStorage.getItem("userData");
        if (!userData) {
          throw new Error("אין מידע משתמש");
        }

        const token = JSON.parse(userData).token;

        const response = await fetchData(
          {
            request: "clients/cancellation-rules/defaults",
          },
          "GET",
          token
        );

        if (!response || !response.success) {
          throw new Error(response?.message || "תגובה לא תקינה מהשרת");
        }

        setDefaultRules(response.rules);
        setErrorMessage("");
      } catch (error) {
        setErrorMessage(`שגיאה בטעינת הגדרות ברירת מחדל: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchDefaultRules();
  }, []);

  const validateNewTimeRange = (startTime, endTime) => {
    for (const range of timeRanges) {
      const existingStart = convertTimeToMinutes(range.startTime);
      const existingEnd = convertTimeToMinutes(range.endTime);
      const newStart = convertTimeToMinutes(startTime);
      const newEnd = convertTimeToMinutes(endTime);

      if (
        (newStart >= existingStart && newStart <= existingEnd) ||
        (newEnd >= existingStart && newEnd <= existingEnd) ||
        (existingStart >= newStart && existingStart <= newEnd)
      ) {
        setErrorMessage("טווח השעות חופף עם טווח קיים");
        return false;
      }
    }

    if (startTime === endTime) {
      setErrorMessage("שעת ההתחלה והסיום זהות");
      return false;
    }

    return true;
  };

  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} שעות ${
      remainingMinutes > 0 ? `ו-${remainingMinutes} דקות` : ""
    }`.trim();
  };

  const handleAddRange = () => {
    if (
      !newRange.startTime ||
      !newRange.endTime ||
      !newRange.cancelMinutesBefore
    ) {
      setErrorMessage("יש למלא את כל השדות");
      return;
    }

    if (!validateNewTimeRange(newRange.startTime, newRange.endTime)) {
      return;
    }

    setTimeRanges([...timeRanges, { ...newRange }]);

    setNewRange({
      startTime: "",
      endTime: "",
      cancelMinutesBefore: "",
    });
    setErrorMessage("");
  };

  const handleGlobalTimeSet = () => {
    if (!globalCancelTime) {
      setErrorMessage("יש להזין זמן ביטול");
      return;
    }

    const fullDayRanges = [
      {
        startTime: "00:00",
        endTime: "23:59",
        cancelMinutesBefore: globalCancelTime,
      },
    ];

    setTimeRanges(fullDayRanges);
    setUseGlobalTime(true);
    setErrorMessage("");
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).token
        : null;

      if (!token) {
        throw new Error("No authentication token found");
      }

      const saveData = {
        isUsingDefault,
        timeRanges: isUsingDefault ? [] : timeRanges,
      };

      const entityType = context === "client" ? "clients" : "branches";

      const response = await fetchData(
        {
          request: `${entityType}/${client._id}/cancellation-rules`,
          body: saveData,
        },
        "PUT",
        token
      );

      if (response.success) {
        showSuccessToast("הגדרות הביטול נשמרו בהצלחה");
        fetchClientDetails?.();
      } else {
        throw new Error(
          response.message || "Failed to save cancellation rules"
        );
      }
    } catch (error) {
      showErrorToast("שגיאה בשמירת ההגדרות");
    }
  };

  if (loading) {
    return <div>טוען...</div>;
  }

  return (
    <div className="p-3">
      <Form>
        <Form.Group className="mb-4">
          <Form.Check
            type="switch"
            id="use-default-rules"
            label="השתמש בהגדרות ברירת מחדל של המערכת"
            checked={isUsingDefault}
            onChange={(e) => setIsUsingDefault(e.target.checked)}
          />

          {isUsingDefault && defaultRules && (
            <Alert variant="info" className="mt-2">
              <h6>הגדרות ברירת המחדל:</h6>
              <ul>
                {defaultRules.timeRanges.map((rule, index) => (
                  <li key={index}>
                    {rule.startTime}-{rule.endTime}: {rule.cancelMinutesBefore}{" "}
                    דקות לפני מועד ביצוע הנסיעה
                    {" (" +
                      convertMinutesToHours(rule.cancelMinutesBefore) +
                      ")"}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </Form.Group>

        {!isUsingDefault && (
          <>
            <Row className="mb-4">
              <Col md={6}>
                <div className="p-3 border rounded">
                  <h6>הגדרת זמן ביטול גלובלי</h6>
                  <div className="d-flex gap-3 align-items-end">
                    <Form.Group className="mb-0 flex-grow-1">
                      <Form.Label>דקות לפני ביטול (לכל השעות)</Form.Label>
                      <Form.Control
                        type="number"
                        min="1"
                        max="1440"
                        value={globalCancelTime}
                        onChange={(e) => setGlobalCancelTime(e.target.value)}
                      />
                    </Form.Group>
                    <Button variant="secondary" onClick={handleGlobalTimeSet}>
                      החל על כל השעות
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mb-4 p-3 border rounded">
              <h6>הוספת טווח שעות חדש</h6>
              <div className="d-flex gap-3 align-items-end">
                <Form.Group className="mb-0">
                  <Form.Label>שעת התחלה</Form.Label>
                  <Form.Control
                    type="time"
                    value={newRange.startTime}
                    onChange={(e) =>
                      setNewRange({ ...newRange, startTime: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-0">
                  <Form.Label>שעת סיום</Form.Label>
                  <Form.Control
                    type="time"
                    value={newRange.endTime}
                    onChange={(e) =>
                      setNewRange({ ...newRange, endTime: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-0">
                  <Form.Label>דקות לפני ביטול</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max="1440"
                    value={newRange.cancelMinutesBefore}
                    onChange={(e) =>
                      setNewRange({
                        ...newRange,
                        cancelMinutesBefore: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Button variant="primary" onClick={handleAddRange}>
                  הוסף
                </Button>
              </div>
              {errorMessage && (
                <Alert variant="danger" className="mt-2">
                  {errorMessage}
                </Alert>
              )}
            </div>

            <div className="mb-4">
              <h6>טווחי שעות מוגדרים</h6>
              {timeRanges.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>שעת התחלה</th>
                      <th>שעת סיום</th>
                      <th>דקות לפני ביטול</th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeRanges.map((range, index) => (
                      <tr key={index}>
                        <td>{range.startTime}</td>
                        <td>{range.endTime}</td>
                        <td>
                          {range.cancelMinutesBefore} דקות לפני מועד ביצוע
                          הנסיעה
                          {" (" +
                            convertMinutesToHours(range.cancelMinutesBefore) +
                            ")"}
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              setTimeRanges(
                                timeRanges.filter((_, i) => i !== index)
                              );
                            }}
                          >
                            מחק
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Alert variant="info">אין טווחי שעות מוגדרים</Alert>
              )}
            </div>
          </>
        )}

        <Button variant="primary" onClick={handleSave}>
          שמור הגדרות
        </Button>
      </Form>
    </div>
  );
}
