import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function TripsSummary({
  data,
  getDateRangeDisplay,
  tripAnalyticsData,
  formatNumberWithCommas,
}) {
  // הנתונים נמצאים ישירות ב-data.tripsAnalytics
  const analytics = data.tripsAnalytics || {};

  return (
    <Card className="h-100 border border-1">
      <Card.Header className="font-weight-bold">
        <span>סיכום נסיעות {getDateRangeDisplay()}</span>
      </Card.Header>
      <Card.Body>
        <Row>
          {tripAnalyticsData.map((trip) => (
            <React.Fragment key={trip.type}>
              <Col xs={12} md={6} className="mb-3">
                <Card className="h-100 border border-1">
                  <Card.Header className={`${trip.color} text-light`}>
                    סה״כ {trip.title}
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h4 className="text-center m-0 font-weight-bold">
                      {trip.count}
                    </h4>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Card className="h-100 border border-1">
                  <Card.Header className={`${trip.color} text-light`}>
                    סה״כ סכום {trip.title}
                  </Card.Header>
                  <Card.Body className="text-center">
                    <h4 className="text-center m-0 font-weight-bold">
                      ₪{formatNumberWithCommas(trip.amount)}
                    </h4>
                  </Card.Body>
                </Card>
              </Col>
            </React.Fragment>
          ))}
        </Row>

        <Row>
          <Col xs={12} md={12} className="mb-1">
            <Card className="h-100 border border-1">
              <Card.Header className={`bg-info text-light`}>סיכום</Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>סה"כ לגבייה מלקוחות</td>
                        <td>
                          ₪
                          {formatNumberWithCommas(
                            analytics.totalTakersAmount || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>סה"כ לתשלום לקבלנים</td>
                        <td>
                          ₪
                          {formatNumberWithCommas(
                            analytics.totalPublishersAmount || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>הפרש בש"ח</td>
                        <td>
                          ₪
                          {formatNumberWithCommas(
                            analytics.amountDifference || 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>הפרש באחוזים</td>
                        <td>
                          {analytics.percentageDifference == null
                            ? "N/A"
                            : `${analytics.percentageDifference.toFixed(2)}%`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TripsSummary;
