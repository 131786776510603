const BundleTag = ({ color, count }) => (
  <span
    className="d-inline-flex align-items-center justify-content-center px-2 py-1 rounded-pill me-2"
    style={{
      backgroundColor: color,
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: "bold",
      minWidth: "80px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    }}
  >
    בנדל {count}
  </span>
);

export default BundleTag;
