// src/context/ToastContext.jsx
import React, { createContext, useContext, useState } from "react";
import ToastComponent from "../components/Toast/Toast";

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");

  const showSuccessToast = (message) => {
    setToastType("success");
    setToastMessage(message);
    setShowToast(true);
  };

  const showErrorToast = (message) => {
    setToastType("danger"); // שינינו מ-"error" ל-"danger"
    setToastMessage(message);
    setShowToast(true);
  };

  const showWarningToast = (message) => {
    setToastType("warning");
    setToastMessage(message);
    setShowToast(true);
  };

  const value = {
    showSuccessToast,
    showErrorToast,
    showWarningToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastComponent
        type={toastType}
        message={toastMessage}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
