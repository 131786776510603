import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HeaderSection = ({
  dateRange,
  setDateRange,
  showCustomRange,
  setShowCustomRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
      <h2 className="font-weight-bold">לוח הבקרה</h2>
      <div className="d-flex flex-column flex-md-row gap-2">
        {[
          { label: "היום", value: "today" },
          { label: "מחר", value: "tomorrow" },
          { label: "שבוע אחרון", value: "week" },
          { label: "מתחילת החודש עד היום", value: "month" },
          { label: "שבוע הבא", value: "nextWeek" },
          { label: "כל החודש", value: "fullMonth" },
        ].map(({ label, value }) => (
          <Button
            key={value}
            variant={dateRange === value ? "primary" : "light"}
            onClick={() => setDateRange(value)}
            className="mb-2 mb-md-0"
          >
            {label}
          </Button>
        ))}
        <Button
          variant={dateRange === "custom" ? "primary" : "light"}
          onClick={() => setShowCustomRange(true)}
        >
          טווח מותאם
        </Button>
      </div>

      <Modal show={showCustomRange} onHide={() => setShowCustomRange(false)}>
        <Modal.Header closeButton>
          <Modal.Title>בחירת טווח תאריכים</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>תאריך התחלה</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>תאריך סיום</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCustomRange(false)}>
            ביטול
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (startDate && endDate) {
                setShowCustomRange(false);
              }
            }}
          >
            אישור
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HeaderSection;
