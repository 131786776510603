// Dashboard.js - Parent Component

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../context/LoadingContext";
import { fetchData } from "../../utils/fetchData";
import { formatNumberWithCommas } from "../../utils/supportFunctions";
import { DASHBOARD_SECTIONS, PAGES } from "../../config/permissions";
import { withPermissions } from "../../components/hoc/withPermissions";
import TripsSummary from "./TripsSummary";
import GeneralInfo from "./GeneralInfo";
import TripsByBranch from "./TripsByBranch";
import HeaderSection from "./HeaderSection";
import TripsByClient from "./TripsByClient";
import PieChartSummary from "./PieChartSummary";

const Dashboard = ({ hasPermission }) => {
  const navigate = useNavigate();

  const { showLoading, hideLoading } = useLoading();
  const [dateRange, setDateRange] = useState(() => {
    return localStorage.getItem("dashboardDateRange") || "today";
  });
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dashboardData, setDashboardData] = useState(initialDashboardData);
  const [showAllClients, setShowAllClients] = useState(false);
  const [showAllBranches, setShowAllBranches] = useState(false);
  const tripsData = [
    {
      type: "published",
      color: "bg-primary",
      title: "נסיעות שפורסמו",
      count: dashboardData.tripsAnalytics?.publishedTrips || 0,
      amount: dashboardData.tripsAnalytics?.publishedTripsAmount || 0,
    },
    {
      type: "taken",
      color: "bg-warning",
      title: "נסיעות שנלקחו",
      count: dashboardData.tripsAnalytics?.takenTrips || 0,
      amount: dashboardData.tripsAnalytics?.takenTripsAmount || 0,
    },
    {
      type: "performed",
      color: "bg-success",
      title: "נסיעות שבוצעו והסתיימו",
      count: dashboardData.tripsAnalytics?.performedTrips || 0,
      amount: dashboardData.tripsAnalytics?.performedTripsAmount || 0,
    },
    {
      type: "canceled",
      color: "bg-secondary",
      title: "נסיעות שבוטלו",
      count: dashboardData.tripsAnalytics?.canceledTrips || 0,
      amount: dashboardData.tripsAnalytics?.canceledTripsAmount || 0,
    },
    {
      type: "open",
      color: "bg-danger",
      title: "נסיעות פתוחות",
      count: dashboardData.tripsAnalytics?.openTrips || 0,
      amount: dashboardData.tripsAnalytics?.openTripsAmount || 0,
    },
  ];

  const fetchDashboardData = async () => {
    showLoading();
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        navigate("/login");
        return;
      }
      const token = JSON.parse(userData).token;

      let requestUrl = `dashboard?range=${dateRange}`;
      if (dateRange === "custom" && startDate && endDate) {
        requestUrl += `&startDate=${formatDate(startDate)}&endDate=${formatDate(
          endDate
        )}`;
      }

      const response = await fetchData(
        { request: requestUrl },
        "GET",
        token,
        {},
        navigate
      );
      if (response?.data) {
        setDashboardData((prevData) => ({ ...prevData, ...response.data }));
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (dateRange !== "custom" || (startDate && endDate)) {
      fetchDashboardData();
    }
  }, [dateRange, startDate, endDate]);

  const updateDateRange = (newRange) => {
    if (dateRange !== newRange) {
      setDateRange(newRange);
      localStorage.setItem("dashboardDateRange", newRange);
    }
  };

  const getDateRangeDisplay = () => {
    const now = new Date();

    switch (dateRange) {
      case "today":
        return "להיום";
      case "tomorrow":
        return "למחר";
      case "nextWeek": // הוספת אפשרות שבוע הבא
        const nextWeekStart = new Date(now);
        nextWeekStart.setDate(now.getDate());
        const nextWeekEnd = new Date(nextWeekStart);
        nextWeekEnd.setDate(nextWeekStart.getDate() + 7);
        return `${nextWeekStart.toLocaleDateString(
          "he-IL"
        )} - ${nextWeekEnd.toLocaleDateString("he-IL")}`;
      case "week":
        const weekAgo = new Date(now);
        weekAgo.setDate(now.getDate() - 7);
        return `${weekAgo.toLocaleDateString(
          "he-IL"
        )} - ${now.toLocaleDateString("he-IL")}`;
      case "month":
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        return `${monthStart.toLocaleDateString(
          "he-IL"
        )} - ${now.toLocaleDateString("he-IL")}`;
      case "fullMonth": // הוספת אפשרות כל החודש
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return `${firstDay.toLocaleDateString(
          "he-IL"
        )} - ${lastDay.toLocaleDateString("he-IL")}`;
      case "custom":
        if (startDate && endDate) {
          return `${startDate.toLocaleDateString(
            "he-IL"
          )} - ${endDate.toLocaleDateString("he-IL")}`;
        }
        return "";
      default:
        return "להיום";
    }
  };

  return (
    <Container fluid className="p-4">
      <HeaderSection
        dateRange={dateRange}
        setDateRange={updateDateRange}
        showCustomRange={showCustomRange}
        setShowCustomRange={setShowCustomRange}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      <Row className="mb-4">
        <Col md={6} xs={12}>
          {hasPermission(DASHBOARD_SECTIONS.TRIPS) && (
            <Col>
              <TripsSummary
                data={dashboardData}
                getDateRangeDisplay={getDateRangeDisplay}
                formatNumberWithCommas={formatNumberWithCommas}
                tripAnalyticsData={tripsData}
              />
            </Col>
          )}

          {hasPermission(DASHBOARD_SECTIONS.GENERALINFO) && (
            <Col className="mt-3">
              <GeneralInfo
                data={dashboardData.users}
                getDateRangeDisplay={getDateRangeDisplay}
              />
            </Col>
          )}
        </Col>
        <Col md={6} xs={12}>
          {hasPermission(DASHBOARD_SECTIONS.ANALYTICS) && (
            <Col>
              <PieChartSummary
                data={dashboardData.trips}
                getDateRangeDisplay={getDateRangeDisplay}
              />
            </Col>
          )}
          <Col className="mt-3">
            {hasPermission(DASHBOARD_SECTIONS.BRANCHES) && (
              <TripsByBranch
                data={dashboardData.detailed.allBranchStats}
                showAllBranches={showAllBranches}
                setShowAllBranches={setShowAllBranches}
                getDateRangeDisplay={getDateRangeDisplay}
              />
            )}
          </Col>
          {hasPermission(DASHBOARD_SECTIONS.CLIENTS) && (
            <Col className="mt-3">
              <TripsByClient
                data={dashboardData.detailed.allClientStats}
                showAllClients={showAllClients}
                setShowAllClients={setShowAllClients}
                getDateRangeDisplay={getDateRangeDisplay}
              />
            </Col>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const initialDashboardData = {
  tripsAnalytics: {},
  users: {},
  detailed: {
    branchStats: [],
    clientStats: [],
  },
};

const formatDate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

export default withPermissions(Dashboard, PAGES.DASHBOARD);
