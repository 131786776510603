import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  checkClientExists,
  fetchClientIdByDriverCode,
  formatNumberWithCommas,
} from "../../../utils/supportFunctions";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastContext";
import { fetchData } from "../../../utils/fetchData";

export default function OrderDetailsRevenueSection({ tripDetails }) {
  const { showErrorToast } = useToast();
  const [clientDetails, setClientDetails] = useState(null);
  const navigate = useNavigate();

  // Fetch client details when trip client name changes
  useEffect(() => {
    const fetchClientDetails = async () => {
      const client = await checkClientExists(tripDetails?.clientName);
      setClientDetails(client);
    };

    fetchClientDetails();
  }, [tripDetails?.clientName]);

  const checkBranchExists = async (branchName) => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) return null;

      const token = JSON.parse(userData).token;
      if (!token) return null;

      const response = await fetchData(
        {
          request: `trip/check-branch/${encodeURIComponent(branchName)}`,
        },
        "GET",
        token
      );

      if (response.success && response.branch) {
        return response.branch;
      }
      return null;
    } catch (error) {
      console.error("Error checking branch:", error);
      return null;
    }
  };

  const handleNavigateToCompany = async (companyName) => {
    try {
      if (!companyName) {
        showErrorToast("שם חברה חסר");
        return;
      }

      // בדיקה אם זה סניף
      const branchResult = await checkBranchExists(companyName);
      if (branchResult) {
        navigate(`/dashboard/branches/${branchResult.id}`);
        return;
      }

      // בדיקה אם זה לקוח
      const clientResult = await checkClientExists(companyName);
      if (clientResult) {
        navigate(`/dashboard/clients/${clientResult.id}`);
        return;
      }

      showErrorToast("לא נמצא סניף או לקוח מתאים");
    } catch (error) {
      console.error("Error navigating to company page:", error);
      showErrorToast("שגיאה בניווט לדף החברה");
    }
  };

  // Calculate fees
  const calculatePublisherFee = () => {
    if (!tripDetails?.netClientPrice || !tripDetails?.publisherFee) return 0;
    return tripDetails.netClientPrice * (tripDetails.publisherFee / 100);
  };

  const calculateTakerFee = () => {
    if (!tripDetails?.netClientPrice || !tripDetails?.takerFee) return 0;
    return tripDetails.netClientPrice * (tripDetails.takerFee / 100);
  };

  const totalFees = calculatePublisherFee() + calculateTakerFee();

  return (
    <Row className="align-items-stretch row-gap-3">
      {/* Publisher Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header style={{ background: "#d4edda", color: "#155724" }}>
            <span className="font-weight-bold">חברה מציעה</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <Card.Title className="d-flex justify-content-between w-100">
                  <span
                    className="font-weight-bold hover-link"
                    onClick={() =>
                      handleNavigateToCompany(tripDetails?.clientName)
                    }
                  >
                    {tripDetails?.clientName ?? "-"}
                  </span>
                </Card.Title>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">
              מ.פרסום:{" "}
              {formatNumberWithCommas(tripDetails?.netClientPrice) ?? "-"}
            </span>
            |
            <span className="font-weight-bold">
              עמלה: {tripDetails?.publisherFee ?? "-"}%
            </span>
            |
            <span className="font-weight-bold">
              לתשלום:{" "}
              {formatNumberWithCommas(
                tripDetails?.netClientPrice + calculatePublisherFee()
              ).toLocaleString()}
            </span>
          </Card.Footer>
        </Card>
      </Col>

      {/* Taker Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header style={{ background: "#fff4cc", color: "#cc7a00" }}>
            <span className="font-weight-bold">חברה מבצעת</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <Card.Title className="d-flex justify-content-between w-100">
                  <span
                    className="font-weight-bold hover-link"
                    onClick={() =>
                      handleNavigateToCompany(
                        tripDetails?.performingCompanyName
                      )
                    }
                  >
                    {tripDetails?.performingCompanyName === ""
                      ? "-"
                      : tripDetails?.performingCompanyName}
                  </span>
                </Card.Title>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">
              מ.לקיחה:{" "}
              {formatNumberWithCommas(tripDetails?.netClientPrice ?? "-")}
            </span>
            |
            <span className="font-weight-bold">
              עמלה: {tripDetails?.takerFee ?? "-"}
              {tripDetails?.takerFee > 0 && "%"}
            </span>
            |
            <span className="font-weight-bold">
              לתשלום:{" "}
              {tripDetails?.takerFee > 0
                ? formatNumberWithCommas(
                    tripDetails?.netClientPrice - calculateTakerFee()
                  ).toLocaleString()
                : "-"}
            </span>
          </Card.Footer>
        </Card>
      </Col>

      {/* Fee Details Section */}
      <Col xs={12} md={4}>
        <Card className="border border-1 h-100">
          <Card.Header>
            <span className="font-weight-bold">פירוט עמלות</span>
          </Card.Header>
          <Card.Body className="d-flex justify-content-between align-items-center p-3">
            <div className="w-100">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between w-100">
                  <span className="font-weight-bold">חברה מציעה:</span>
                  <span className="font-weight-bold">
                    {formatNumberWithCommas(calculatePublisherFee())}
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <span className="font-weight-bold">חברה מבצעת:</span>
                  <span className="font-weight-bold">
                    {formatNumberWithCommas(calculateTakerFee())}
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between w-100">
            <span className="font-weight-bold">סה"כ:</span>
            <span className="font-weight-bold">
              {formatNumberWithCommas(totalFees)}
            </span>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}
