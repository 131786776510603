import React from "react";
import { Card } from "react-bootstrap";

const GeneralInfo = ({ data }) => {
  return (
    <Card className="h-100 border border-1">
      <Card.Header className="font-weight-bold">
        <span>סטטיסטיקות כלליות</span>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between">
          <p>סה״כ חברות:</p>
          <span className="fw-bold">{data.totalClients}</span>
        </div>
        <div className="d-flex justify-content-between">
          <p>סה״כ סניפים:</p>
          <span className="fw-bold">{data.totalBranches}</span>
        </div>
        <div className="d-flex justify-content-between">
          <p>סדרנים רשומים:</p>
          <span className="fw-bold">{data.organizers}</span>
        </div>
      </Card.Body>
    </Card>
  );
};
export default GeneralInfo;
