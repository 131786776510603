import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./ClientDetails.scss";
import ClientTransactions from "./ClientTransactions";
import ClientDetailsHeader from "./ClientDetailsHeader";
import ClientCardTripsSection from "./ClientCardTripsSection/ClientCardTripsSection";
import BackButton from "../../../components/BackButton/BackButton";
import ClientDetailsContentSection from "./ClientDetailsContentSection/ClientDetailsContentSection";
import NotesSection from "../../../components/NotesSection/NotesSection";
import ClientBranchesSection from "./ClientBranchesSection/ClientBranchesSection";
import ClientBranchesModals from "./ClientBranchesSection/ClientBranchesModals";
import ConfirmationModal from "./ClientBranchesSection/ConfirmationModal";
import { useToast } from "../../../context/ToastContext";
import { useLoading } from "../../../context/LoadingContext";
import { PAGES, CLIENTS_SECTIONS } from "../../../config/permissions";
import { withPermissions } from "../../../components/hoc/withPermissions";
import { usePermissions } from "../../../context/usePermissions";

const ClientDetails = () => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const { id } = useParams();
  const [isClientType, setIsClientType] = useState(true);
  const [client, setClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [notFound, setNotFound] = useState(false);

  // Added states for branch handling
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [existingOrganizers, setExistingOrganizers] = useState(0);
  const [branchDetails, setBranchDetails] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");

  useEffect(() => {
    fetchEntityDetails();
  }, [id]);

  const fetchEntityDetails = async () => {
    showLoading();
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      let response, url;
      try {
        url = `branches/${id}/details`;
        response = await fetchData({ request: url }, "GET", token);
        setIsClientType(false);

        const entityData = {
          ...response.branch,
          _id: response.branch._id,
          name: response.branch.name,
          parentClient: response.branch.parentClient,
        };

        setClient(entityData);
        setEditedClient(entityData);
      } catch (branchError) {
        url = `clients/${id}`;
        response = await fetchData({ request: url }, "GET", token);
        setIsClientType(true);
        setClient(response.client);
        setEditedClient(response.client);
      }
    } catch (error) {
      console.error("Error fetching entity details:", error);
      setClient(null);
      showErrorToast("שגיאה בטעינת פרטי הלקוח/סניף");
      setNotFound(true);
    } finally {
      hideLoading();
    }
  };

  const handleSaveChanges = async () => {
    if (!hasPermission("clients.details", "edit")) {
      showErrorToast("אין לך הרשאה לערוך לקוח");
      return;
    }
    showLoading();
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const url = isClientType ? `clients/${id}` : `branches/${id}/update`;

      const response = await fetchData(
        {
          request: url,
          body: editedClient,
        },
        "PUT",
        token
      );

      if (response.success) {
        await fetchEntityDetails();
        setIsEditing(false);
        showSuccessToast(
          isClientType ? "הלקוח עודכן בהצלחה" : "הסניף עודכן בהצלחה"
        );

        // החזר את התגובה במקרה של שגיאה
        return response;
      } else {
        // זריקת שגיאה עם ההודעה מהשרת
        throw new Error(response.message || "שגיאה בעדכון");
      }
    } catch (error) {
      console.error("Error updating entity details:", error);

      // זריקת השגיאה עם ההודעה המדויקת
      throw error;
    } finally {
      hideLoading();
    }
  };

  const handleImageUpload = async (event) => {
    if (!hasPermission("clients.details", "edit")) {
      showErrorToast("אין לך הרשאה להעלות תמונה");
      return;
    }
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profileImage", file);

    showLoading();
    try {
      const userData = localStorage.getItem("userData");
      const token = JSON.parse(userData).token;

      const response = await fetchData(
        { request: `clients/upload/${id}`, body: formData },
        "POST",
        token,
        true
      );

      await fetchEntityDetails();
      showSuccessToast(response.message);
    } catch (error) {
      console.error("Error uploading image:", error);
      showErrorToast("שגיאה בהעלאת תמונה");
    } finally {
      hideLoading();
    }
  };

  const handleAddBranch = async (branchData) => {
    if (!hasPermission(CLIENTS_SECTIONS.ADD_BRANCHES, "write")) {
      showErrorToast("אין לך הרשאה להוסיף סניף");
      return;
    }
    if (
      !branchData.name ||
      !branchData.billingEmail ||
      !branchData.clientDriverCode
    ) {
      showErrorToast("יש למלא את כל השדות");
      return;
    }

    showLoading();
    const userData = localStorage.getItem("userData");
    const token = userData ? JSON.parse(userData).token : null;

    try {
      const response = await fetchData(
        {
          request: "branches",
          body: {
            name: branchData.name,
            billingEmail: branchData.billingEmail,
            clientDriverCode: branchData.clientDriverCode,
            parentClientId: client._id,
          },
        },
        "POST",
        token
      );

      if (response.success !== false) {
        if (
          response.message?.includes("נדרש אישור להעברת הנתונים לסניף החדש") ||
          response.message?.includes("נדרש אישור")
        ) {
          setExistingOrganizers(response.data.existingOrganizers);
          setBranchDetails({
            name: branchData.name,
            parentClientId: client._id,
            billingEmail: branchData.billingEmail,
            clientDriverCode: branchData.clientDriverCode,
          });
          setShowAddModal(false);
          setShowConfirmationModal(true);
        } else {
          showSuccessToast("הסניף נוצר בהצלחה");
          setShowAddModal(false);
          await fetchEntityDetails();
          window.location.reload();
        }
      } else {
        // טיפול בתגובת שגיאה מהשרת
        showErrorToast(response.message || "שגיאה ביצירת סניף");
      }
    } catch (err) {
      console.error("Error creating branch:", err);

      // טיפול בשגיאות
      const errorMessage = err.message || "אירעה שגיאה ביצירת הסניף";

      showErrorToast(errorMessage);
    } finally {
      hideLoading();
    }
  };

  const handleConfirmAction = async () => {
    if (!selectedAction) {
      showErrorToast("יש לבחור פעולה");
      return;
    }

    showLoading();
    const userData = localStorage.getItem("userData");
    const token = userData ? JSON.parse(userData).token : null;

    try {
      const confirmResponse = await fetchData(
        {
          request: "branches/confirm",
          body: {
            ...branchDetails,
            action: selectedAction,
          },
        },
        "POST",
        token
      );

      if (confirmResponse.success) {
        showSuccessToast(confirmResponse.message);
        setShowConfirmationModal(false);
        setSelectedAction("");
        setBranchDetails(null);
        await fetchEntityDetails();
        window.location.reload();
      } else {
        throw new Error(confirmResponse.message || "שגיאה באישור יצירת סניף");
      }
    } catch (err) {
      console.error("Error during confirmation:", err);
      showErrorToast(err.response?.data?.message || "שגיאה באישור יצירת סניף");
      setShowConfirmationModal(false);
    } finally {
      hideLoading();
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedClient(client);
  };

  const handleInputChange = (e) => {
    setEditedClient({ ...editedClient, [e.target.name]: e.target.value });
  };

  return (
    <Container fluid className="p-4 d-flex flex-column gap-3">
      {notFound && !client ? (
        <Container className="d-flex justify-content-center align-items-center mt-5">
          <p>Client not found.</p>
        </Container>
      ) : (
        client && (
          <>
            <div className="d-flex gap-2 justify-content-start align-items-center">
              <BackButton />
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h1 className="font-weight-bold">
                  {isClientType ? "כרטיס לקוח" : "כרטיס סניף"}
                </h1>
                {isClientType &&
                  !client?.hasBranches &&
                  hasPermission(CLIENTS_SECTIONS.ADD_BRANCHES, "write") && (
                    <Button
                      variant="primary"
                      onClick={() => setShowAddModal(true)}
                      style={{ maxHeight: "40px" }}
                    >
                      הוסף סניף חדש
                    </Button>
                  )}
              </div>
            </div>

            <Row>
              <Col md={8} className="d-flex flex-column gap-3">
                <Row className="row-gap-2">
                  <Col md={12}>
                    <ClientDetailsHeader
                      client={client}
                      handleImageUpload={handleImageUpload}
                      canEdit={hasPermission("clients.details", "edit")}
                    />
                  </Col>
                </Row>
                <ClientCardTripsSection
                  client={client}
                  context={isClientType ? "client" : "branch"}
                />
                <Row className="row-gap-2">
                  <Col md={12}>
                    <ClientDetailsContentSection
                      client={client}
                      context={isClientType ? "client" : "branch"}
                      isEditing={isEditing}
                      editedClient={editedClient}
                      handleInputChange={handleInputChange}
                      handleCancelEdit={handleCancelEdit}
                      handleEditToggle={handleEditToggle}
                      handleImageUpload={handleImageUpload}
                      handleSaveChanges={handleSaveChanges}
                      fetchClientDetails={fetchEntityDetails}
                      canEdit={hasPermission(
                        CLIENTS_SECTIONS.EDIT_CLIENTS,
                        "edit"
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <NotesSection
                  id={client._id}
                  context={isClientType ? "client" : "branch"}
                />
              </Col>
            </Row>
            {client?.hasBranches &&
              hasPermission(CLIENTS_SECTIONS.ADD_BRANCHES, "write") && (
                <Row>
                  <Col>
                    <ClientBranchesSection
                      client={client}
                      fetchClientDetails={fetchEntityDetails}
                      showAddModal={showAddModal}
                      setShowAddModal={setShowAddModal}
                      canEdit={hasPermission(
                        CLIENTS_SECTIONS.EDIT_BRANCHES,
                        "edit"
                      )}
                      canDelete={hasPermission(
                        CLIENTS_SECTIONS.DELETE_BRANCHES,
                        "delete"
                      )}
                    />
                  </Col>
                </Row>
              )}

            <ClientTransactions
              client={client}
              context={isClientType ? "client" : "branch"}
            />

            {/* Shared Modals */}
            <ClientBranchesModals
              show={showAddModal}
              onHide={() => setShowAddModal(false)}
              onSave={handleAddBranch}
              title="הוספת סניף חדש"
              saveButtonText="שמור"
            />

            <ConfirmationModal
              show={showConfirmationModal}
              handleClose={() => setShowConfirmationModal(false)}
              handleConfirm={handleConfirmAction}
              existingOrganizers={existingOrganizers}
              selectedOption={selectedAction}
              setSelectedOption={setSelectedAction}
            />
          </>
        )
      )}
    </Container>
  );
};

export default withPermissions(ClientDetails, PAGES.CLIENTS);
