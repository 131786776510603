import React, { useState, useEffect } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { fetchData } from "../../../../utils/fetchData";
import { useToast } from "../../../../context/ToastContext";
import { usePermissions } from "../../../../context/usePermissions";

const ClientFeeTab = ({ client = {}, context }) => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const [editedFees, setEditedFees] = useState({
    publisherFee: "",
    takerFee: "",
  });
  const [loading, setLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    if (client) {
      setEditedFees({
        publisherFee: client?.publisherFee ?? "",
        takerFee: client?.takerFee ?? "",
      });
    }
  }, [client]);

  const handleFeeChange = (field, value) => {
    setEditedFees((prevFees) => ({
      ...prevFees,
      [field]: value,
    }));
  };

  const handleSaveFees = async () => {
    setShowValidation(true);

    if (
      editedFees.publisherFee === "" ||
      editedFees.takerFee === "" ||
      editedFees.publisherFee < 0 ||
      editedFees.publisherFee > 100 ||
      editedFees.takerFee < 0 ||
      editedFees.takerFee > 100
    ) {
      showErrorToast("יש למלא את כל השדות והעמלות חייבות להיות בין 0 ל-100");
      return;
    }

    setLoading(true);
    try {
      const userData = localStorage.getItem("userData");
      const token = userData ? JSON.parse(userData).token : null;

      if (!token) {
        throw new Error("User is not authenticated");
      }

      let requestEndpoint = "";
      if (context === "client") {
        requestEndpoint = `clients/${client._id}/fees`;
      } else if (context === "branch") {
        requestEndpoint = `branches/${client._id}/fees`;
      } else {
        throw new Error("Invalid context");
      }

      const response = await fetchData(
        { request: requestEndpoint, body: editedFees },
        "PUT",
        token
      );

      if (response.success) {
        showSuccessToast("העמלות שונו בהצלחה");
      } else {
        throw new Error(response.message || "שגיאה בעדכון עמלות");
      }
    } catch (error) {
      console.error("Error updating fees:", error.message);
      showErrorToast(error.message || "שגיאה בעדכון עמלות");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <Form>
        <Row className="mb-3">
          <div>
            <Form.Group controlId="publisherFee" style={{ width: "30%" }}>
              <Form.Label className="font-weight-bold">
                עמלה חברה מציעה
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={editedFees.publisherFee}
                  onChange={(e) =>
                    handleFeeChange("publisherFee", e.target.value)
                  }
                  min="0"
                  max="100"
                  isInvalid={
                    (editedFees.publisherFee === "" ||
                      editedFees.publisherFee < 0 ||
                      editedFees.publisherFee > 100) &&
                    showValidation
                  }
                  required
                />
                <InputGroup.Text className="px-3">%</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  יש למלא את העמלה בין 0 ל-100
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>
          <hr className="my-3" />
          <div>
            <Form.Group controlId="takerFee" style={{ width: "30%" }}>
              <Form.Label className="font-weight-bold">
                עמלה חברה מבצעת
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  value={editedFees.takerFee}
                  onChange={(e) => handleFeeChange("takerFee", e.target.value)}
                  min="0"
                  max="100"
                  isInvalid={
                    (editedFees.takerFee === "" ||
                      editedFees.takerFee < 0 ||
                      editedFees.takerFee > 100) &&
                    showValidation
                  }
                  required
                />
                <InputGroup.Text className="px-3">%</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  יש למלא את העמלה בין 0 ל-100
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>
        </Row>
        <div className="d-flex justify-content-end">
          {hasPermission("clients.fees", "write") && (
            <Button
              onClick={handleSaveFees}
              disabled={loading}
              className="table-btn"
            >
              {loading ? "שומר..." : "שמור שינויים"}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ClientFeeTab;
