import React from "react";
import { MdSpaceDashboard, MdComputer } from "react-icons/md";
import { BsFillPersonVcardFill, BsShield } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { FaBus } from "react-icons/fa";
import { PAGES } from "../../config/permissions"; // נוסיף את זה

export const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    permission: PAGES.DASHBOARD,
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <MdComputer />,
    label: "לקוחות",
    permission: PAGES.CLIENTS,
  },
  {
    to: "/dashboard/trips",
    icon: <FaBus />,
    label: "נסיעות",
    permission: PAGES.TRIPS,
  },
  {
    to: "/dashboard/admins",
    label: "משתמשי מערכת",
    icon: <BsShield />,
    permission: PAGES.ADMINS,
  },
  {
    to: "/dashboard/settings",
    icon: <FiSettings />,
    label: "הגדרות",
    permission: PAGES.SETTINGS,
  },
  {
    to: "/dashboard/activities",
    icon: <FiSettings />,
    label: "פעילות",
    permission: PAGES.ACTIVITIES,
  },
];
