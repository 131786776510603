// config/permissions.js
export const PAGES = {
  ADMINS: "admins",
  ORGANIZERS: "organizers",
  TRIPS: "trips",
  CLIENTS: "clients",
  DASHBOARD: "dashboard",
  SETTINGS: "settings",
  ACTIVITIES: "activities",
};

export const PERMISSIONS = {
  READ: "read",
  WRITE: "write",
  EDIT: "edit",
  DELETE: "delete",
};

export const DASHBOARD_SECTIONS = {
  TRIPS: "dashboard.trips",
  CLIENTS: "dashboard.clients",
  BRANCHES: "dashboard.branches",
  GENERALINFO: "dashboard.generalInfo",
  ANALYTICS: "dashboard.analytics",
};

export const CLIENTS_SECTIONS = {
  ADD_CLIENTS: "clients.addingClients",
  EDIT_CLIENTS: "clients.editClients",
  DELETE_CLIENTS: "clients.deleteClients",
  EXPORT_TRANSACTIONS: "clients.exportingClientTransactions",
  ADD_BRANCHES: "clients.addingBranches",
  EDIT_BRANCHES: "clients.editBranches",
  DELETE_BRANCHES: "clients.deleteBranches",
  DETAILS: "clients.details",
  ORGANIZERS: "clients.organizers",
  FEES: "clients.fees",
  DOCUMENTS: "clients.documents",
  SETTINGS: "clients.settings",
};

export const ADMIN_SECTIONS = {
  USERS: "admins.users",
  MANAGE: "admins.manage",
  PERMISSIONS: "admins.permissions",
};

export const defaultPermissions = [
  {
    key: "dashboard",
    label: "לוח הבקרה",
    group: "core",
    permissions: ["read"],
    order: 1,
  },
  {
    key: "dashboard.trips",
    label: "סיכום נסיעות",
    group: "dashboard",
    permissions: ["read"],
    order: 2,
  },
  {
    key: "dashboard.analytics",
    label: "התפלגות נסיעות",
    group: "dashboard",
    permissions: ["read"],
    order: 3,
  },
  {
    key: "dashboard.clients",
    label: "פעילות לפי חברות",
    group: "dashboard",
    permissions: ["read"],
    order: 4,
  },
  {
    key: "dashboard.branches",
    label: "נסיעות לפי סניפים",
    group: "dashboard",
    permissions: ["read"],
    order: 5,
  },
  {
    key: "dashboard.generalInfo",
    label: "סטטיסטיקות כלליות",
    group: "dashboard",
    permissions: ["read"],
    order: 6,
  },
  {
    key: "clients",
    label: "לקוחות",
    group: "core",
    permissions: ["read", "write", "edit", "delete"],
    order: 7,
  },
  {
    key: "clients.addingClients",
    label: "כפתור הוספת לקוח",
    group: "clients",
    permissions: ["write"],
    order: 8,
  },
  {
    key: "clients.editClients",
    label: "כפתור עריכת לקוח",
    group: "clients",
    permissions: ["edit"],
    order: 9,
  },
  {
    key: "clients.deleteClients",
    label: "כפתור מחיקת לקוח",
    group: "clients",
    permissions: ["delete"],
    order: 10,
  },
  {
    key: "clients.exportingClientTransactions",
    label: "כפתור ייצוא אקסל",
    group: "clients",
    permissions: ["read"],
    order: 11,
  },
  {
    key: "clients.addingBranches",
    label: "כפתור הוספת סניף",
    group: "branches",
    permissions: ["write"],
    order: 12,
  },
  {
    key: "clients.editBranches",
    label: "כפתור עריכת סניף",
    group: "branches",
    permissions: ["edit"],
    order: 13,
  },
  {
    key: "clients.deleteBranches",
    label: "כפתור מחיקת סניף",
    group: "branches",
    permissions: ["delete"],
    order: 14,
  },
  {
    key: "clients.exportingBranchesTransactions",
    label: "כפתור ייצוא אקסל",
    group: "branches",
    permissions: ["write"],
    order: 15,
  },
  {
    key: "trips",
    label: "נסיעות",
    group: "core",
    permissions: ["read", "write", "edit", "delete"],
    order: 16,
  },
  {
    key: "admins",
    label: "משתמשי מערכת",
    group: "core",
    permissions: ["read", "write", "edit", "delete"],
    order: 17,
  },
  {
    key: "admins.manage",
    label: "עריכת משתמשי מערכת",
    group: "admins",
    permissions: ["read", "write", "edit", "delete"],
    order: 18,
  },
  {
    key: "settings",
    label: "הגדרות",
    group: "core",
    permissions: ["read", "write", "edit", "delete"],
    order: 19,
  },
  {
    key: "activities",
    label: "פעילות",
    group: "core",
    permissions: ["read", "write", "edit", "delete"],
    order: 20,
  },
  {
    key: "clients.organizers",
    label: "כרטייסת סדרנים",
    group: "clients",
    permissions: ["read", "write", "edit", "delete"],
    order: 21,
  },
  {
    key: "clients.fees",
    label: "כרטייסת עמלות",
    group: "clients",
    permissions: ["read", "write"],
    order: 22,
  },
  {
    key: "clients.documents",
    label: "כרטייסת מסמכים",
    group: "clients",
    permissions: ["read"],
    order: 23,
  },
  {
    key: "clients.settings",
    label: "כרטייסת הגדרות",
    group: "clients",
    permissions: ["read", "write", "delete"],
    order: 24,
  },
  {
    key: "clients.details",
    label: "כרטייסת פרטים",
    group: "clients",
    permissions: ["read", "write", "edit"],
    order: 25,
  },
];
