// AdminOtpLogin.jsx
import React, { useEffect, useState } from "react";
import "./login.scss";
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import Logo from "../../assets/icons/logo.svg";
import { fetchData } from "../../utils/fetchData";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "../../context/usePermissions";

const AdminOtpLogin = () => {
  const [inputOtp, setInputOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();
  const { setPermissions } = usePermissions();

  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || "";
  const lastFourDigits = phoneNumber?.slice(-4);

  useEffect(() => {
    if (!phoneNumber) {
      navigate("/login"); // Redirect to admin login screen
    }
  }, [phoneNumber, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const loginResponse = await fetchData(
        {
          request: "admins/login-otp",
          phoneNumber,
          otp: inputOtp,
        },
        "POST"
      );
      localStorage.setItem("userData", JSON.stringify(loginResponse));

      const userResponse = await fetchData(
        { request: "admins/current" },
        "GET",
        loginResponse.token
      );

      if (userResponse.success) {
        setPermissions(userResponse.permissions);
        const userData = JSON.parse(localStorage.getItem("userData"));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            permissions: userResponse.permissions,
          })
        );
      }

      setLoading(false);
      setShowErr(false);
      navigate("/dashboard/trips");
    } catch (error) {
      setErrMessage(error.message);
      setShowErr(true);
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login__wrapper">
        <Form
          className="shadow p-4 bg-white rounded login__form"
          onSubmit={handleSubmit}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
            src={Logo}
            alt="logo"
          />
          <div className="h5 mb-2 text-right">
            התחברות למערכת עם קוד חד-פעמי
          </div>
          <div className="mb-2 text-right">
            שלחנו SMS עם קוד חד-פעמי למספר טלפון נייד שמסתיים בספרות{" "}
            {lastFourDigits}
          </div>
          {showErr && <Alert variant="danger">{errMessage}</Alert>}

          <Form.Group className="mb-2" controlId="otp">
            <Form.Label>קוד האימות</Form.Label>
            <InputGroup>
              <Form.Control
                type="tel"
                value={inputOtp}
                placeholder="הכנס את הקוד שקיבלת בהודעה"
                onChange={(e) => setInputOtp(e.target.value)}
                style={{ textAlign: "right" }}
              />
            </InputGroup>
          </Form.Group>

          {!loading ? (
            <Button className="w-100" variant="primary" type="submit">
              המשך
            </Button>
          ) : (
            <Button className="w-100" variant="primary" type="submit" disabled>
              רק רגע...
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default AdminOtpLogin;
