/**
 *  function  that cancel the scrolling in the page if the value is true
 * @param {boolean} turnOn
 */

import { fetchData } from "./fetchData";

export const cancel_scrolling = (turnOn) => {
  const htmlBody = document.getElementsByTagName("body")[0];
  if (turnOn) {
    htmlBody.style.overflow = "hidden";
  } else {
    htmlBody.style.overflow = "auto";
  }
};

/**
 * Convert client type to a readable text.
 * @param {number} clientType
 * @returns {string}
 */
export const getClientTypeText = (clientType) => {
  switch (clientType) {
    case 1:
      return "חברה פרטית";
    case 2:
      return "חברה ציבורית";
    case 3:
      return "עוסק מורשה";
    default:
      return "סוג לא ידוע";
  }
};

// Utility function to format number with commas and two decimal places
export const formatNumberWithCommas = (number) => {
  const roundedNumber = Math.round(number);
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(roundedNumber);
};

export const carTypes = [
  { value: "all", label: "כל סוגי הרכב" },
  { value: "אוטובוס", label: "אוטובוס" },
  { value: "מונית", label: "מונית" },
  { value: "מידיבוס", label: "מידיבוס" },
  { value: "מיניבוס", label: "מיניבוס" },
  { value: "מעלון", label: "מעלון" },
  { value: "רכב 10", label: "רכב 10" },
  { value: "רכב 14", label: "רכב 14" },
  { value: "רכב 16", label: "רכב 16" },
];

export const calculatePriceWithVAT = (price, vatRate = 0.17) => {
  return price + price * vatRate;
};

export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split(" ")[0].split("-");
  return `${day}.${month}.${year}`;
};

export const formatDateAndTime = (isoDate) => {
  const date = new Date(isoDate);

  // Extract the date components
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Combine the components into the desired format
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const checkClientExists = async (clientName) => {
  try {
    const userData = localStorage.getItem("userData");
    const token = JSON.parse(userData)?.token;
    const response = await fetchData(
      { request: `trip/check-client/${clientName}` },
      "GET",
      token
    );

    if (response && response.success) {
      return response.client; // Return client details
    } else if (response && response.status === 404) {
      console.warn(`Client "${clientName}" not found`);
      return null; // Return null if client not found
    } else {
      console.error("Error checking client:", response.message);
      return null;
    }
  } catch (error) {
    console.error("Error checking client:", error.message);
    return null;
  }
};

export const fetchClientIdByDriverCode = async (driverCode) => {
  try {
    if (!driverCode) {
      console.error("Driver code is required.");
      return null;
    }

    const response = await fetchData(
      { request: `clients/find-client-id/${driverCode}` },
      "GET"
    );

    if (response?.clientId) {
      return response.clientId;
    } else {
      console.error("Client ID not found for driver code.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching client ID by driver code:", error);
    return null;
  }
};

export const fetchClientIdByName = async (clientName) => {
  try {
    if (!clientName) {
      console.error("Client name is required.");
      return null;
    }

    const encodedClientName = encodeURIComponent(clientName);

    const response = await fetchData(
      { request: `clients/find-client-id/${encodedClientName}` },
      "GET"
    );

    if (response?.clientId) {
      return response.clientId;
    } else {
      console.error("Client ID not found for client name.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching client ID by client name:", error);
    return null;
  }
};
