import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ClientsTable from "./clientsTable/clientsTable";
import { CreateStationModal } from "./createClientModal/CreateClientModal";
import { fetchData } from "../../utils/fetchData";
import { Container } from "react-bootstrap";
import { useLoading } from "../../context/LoadingContext";
import { PAGES, CLIENTS_SECTIONS } from "../../config/permissions";
import { withPermissions } from "../../components/hoc/withPermissions";
import { usePermissions } from "../../context/usePermissions";

const Clients = ({ hasPermission }) => {
  const { showLoading, hideLoading } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  // Initialize state from URL params
  const [initialState] = useState(() => ({
    currentPage: parseInt(searchParams.get("page") || "1"),
    searchTerm: searchParams.get("search") || "",
    sortField: searchParams.get("sortField") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    selectedCity: searchParams.get("city") || "",
    clientType: searchParams.get("clientType") || "",
    selectedState: searchParams.get("state") || "",
  }));

  const updateUrlParams = (updates, triggerSearch = false) => {
    const newParams = Object.fromEntries(searchParams.entries());

    Object.keys(updates).forEach((key) => {
      if (updates[key]) {
        newParams[key] = updates[key];
      } else {
        delete newParams[key];
      }
    });

    if (triggerSearch) {
      setSearchParams(newParams, { replace: true });
    }

    return newParams;
  };

  // FETCHING STATIONS FROM SERVER
  const fetchClients = async (page = 1, params = {}) => {
    try {
      showLoading();
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const queryParams = {
        page,
        limit: 25,
        ...params,
      };

      const requestObject = { request: "clients" };
      const response = await fetchData(
        requestObject,
        "GET",
        token,
        queryParams
      );
      setClients(response.clients);
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
      setCities(response.cities || []);
      setStates(response.states || []);
    } catch (err) {
      setError(err.message);
    } finally {
      hideLoading();
    }
  };

  // Listen for URL changes
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    fetchClients(params.page || 1, params);
  }, [searchParams]);

  return (
    <Container fluid className="p-4">
      <div className="pb-3">
        <h2 className="font-weight-bold">לקוחות</h2>
      </div>

      <ClientsTable
        setIsOpen={setIsOpen}
        setClient={setClient}
        fetchClients={fetchClients}
        clients={clients}
        totalPages={totalPages}
        loading={false}
        initialState={initialState}
        updateUrlParams={updateUrlParams}
        cities={cities}
        states={states}
        hasPermission={hasPermission}
      />

      <CreateStationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fetchClients={fetchClients}
        client={client}
        setClient={setClient}
        clients={clients}
      />
    </Container>
  );
};

export default withPermissions(Clients, PAGES.CLIENTS);
