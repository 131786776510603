import { useState, useEffect, useCallback, useRef } from "react";
import { Modal, Button } from "react-bootstrap";

const INACTIVITY_TIME = 60 * 60 * 1000; // שעה במילישניות
const CHECK_INTERVAL = 60000; // בדיקה כל דקה

// const INACTIVITY_TIME = 20000; // 20 seconds for testing
// const CHECK_INTERVAL = 5000; // check every 5 seconds

// const INACTIVITY_TIME = 30000; // 30 seconds for testing
// const CHECK_INTERVAL = 10000; // check every 10 seconds

export const useInactivityCheck = (onInactive) => {
  const [showModal, setShowModal] = useState(false);
  const lastActivity = useRef(Date.now());
  const checkIntervalRef = useRef(null); // שינוי השם למשמעותי יותר

  const handleUserActivity = useCallback(() => {
    // נוסיף throttle לעדכונים תכופים
    if (!showModal && Date.now() - lastActivity.current > 1000) {
      lastActivity.current = Date.now();
    }
  }, [showModal]);

  const handleModalConfirm = useCallback(() => {
    lastActivity.current = Date.now();
    setShowModal(false);
    if (onInactive) {
      onInactive(false);
    }
  }, [onInactive]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear(); // נוסיף גם ניקוי של session storage
    window.location.href = "/login";
  }, []);

  useEffect(() => {
    const checkInactivity = () => {
      const timeSinceLastActivity = Date.now() - lastActivity.current;

      if (timeSinceLastActivity >= INACTIVITY_TIME && !showModal) {
        setShowModal(true);
        if (onInactive) {
          onInactive(true);
        }
      }
    };

    // מאזינים לכל סוגי הפעילות
    const events = ["scroll", "click"];

    events.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    // בדיקת אי-פעילות בפרקי זמן קבועים
    checkIntervalRef.current = setInterval(checkInactivity, CHECK_INTERVAL);

    // ניקוי בעת הורדת הקומפוננטה
    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
    };
  }, [handleUserActivity, onInactive, showModal]);

  const InactivityModal = () => (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={() => {}} // מונע סגירה עם Escape
    >
      <Modal.Header>
        <Modal.Title>האם אתה עדיין כאן?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        לא זוהתה פעילות בשעה האחרונה. האם להמשיך לרענן את הנתונים?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleModalConfirm}>
          כן, המשך רענון
        </Button>
        <Button variant="danger" onClick={handleLogout}>
          התנתק
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return { InactivityModal, isInactive: showModal };
};
